import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Layout from "components/Layout";

import CustomTable from "../../components/Table";
import LoadingTable from "../../components/Table/loading";
import NameAndEmail from "../../components/Table/components/NameAndEmail";
import CustomDate from "../../components/Table/components/CustomDate";
import BackwardAndTitle from "../../components/BackwardAndTitle";
import ReactPaginate from "react-paginate";

import formatCountry from "utils/formatCountry";

import { getLeads } from "../../services/api";

function Row({ data }) {
  return (
    <tr>
      <td>
        <NameAndEmail
          name={data?.user?.public_name}
          email={data?.user?.email}
        />
      </td>
      <td>
        <CustomDate date={data.created_at} />
      </td>
      <td>
        <span>{data.notebook.name}</span>
      </td>
      <td>
        <span>
          {data?.user?.country ? formatCountry(data?.user?.country) : "-"}
        </span>
      </td>
      <td>
        <span>
          {data?.user?.city && data?.user?.state
            ? `${data?.user?.city} / ${data?.user?.state}`
            : "-"}
        </span>
      </td>
    </tr>
  );
}

function Leads() {
  const days = useSelector((state) => state.general.days);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    { field: "name", headerName: "Nome" },
    { field: "created_at", headerName: "Data" },
    { field: "product", headerName: "Produto" },
    { field: "country", headerName: "País" },
    { field: "state_city", headerName: "Cidade/Estado" },
  ];

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        const { data: _leads } = await getLeads({
          page: currentPage,
          days,
        });
        const _rows = [];

        for (const [index, value] of _leads.results.entries()) {
          _rows.push(<Row data={value} key={index} />);
        }

        setRows(_rows);
        setPageCount(Math.ceil(_leads.count / 10));
        setLoading(false);
      } catch (error) {
        console.error("Falha ao buscar clientes", error);
        setPageCount(1);
        setLoading(false);
      }
    }

    return init();
  }, [currentPage, days]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "2rem",
          width: "100%",
        }}
      >
        <BackwardAndTitle
          loading={loading}
          title="Leads"
          breadcrumb={[
            {
              href: "/",
              label: "Visão Geral",
            },
            {
              label: "Leads",
            },
          ]}
        />
        <div className="card">
          {loading ? (
            <LoadingTable />
          ) : (
            <CustomTable columns={columns} rows={rows} />
          )}
          <div className="card-footer flex-end">
            <ReactPaginate
              previousLabel="Anterior"
              nextLabel="Próxima"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-end"
              activeClassName="active"
              pageClassName="page-item"
              nextClassName="page-link"
              pageLinkClassName="page-link"
              previousClassName="page-link"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Leads;
