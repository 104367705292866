import pad from './pad';

export default function formatDate(date) {
    const objDate = new Date(date);

    const day = pad(objDate.getDate());
    const month = pad(objDate.getMonth()+1);
    const hours = pad(objDate.getHours());
    const minutes = pad(objDate.getMinutes());
    const secounds = pad(objDate.getSeconds());
    const shortYear = String(objDate.getFullYear()).substring(2, 4);

    return ({
        day,
        month,
        strMonth: objDate.toLocaleDateString('default', { month: 'long' }),
        fullYear: objDate.getFullYear(),
        shortYear,
        formatedDate: `${day}/${month}/${objDate.getFullYear()}`,
        formatedDateShort: `${day}/${month}/${shortYear}`,
        hours,
        minutes,
        secounds,
        formatedTimeHM: `${hours}:${minutes}`,
        formatedTimeHMS: `${hours}:${minutes}:${secounds}`,
        date: objDate
    });
}