import React from "react";
import { Link } from "react-router-dom";
import { MdMoreVert } from "react-icons/md";

function DropdownButton({ childs }) {
  return (
    <div className="dropdown">
      <button
        style={{ width: 16, padding: 8, border: "none" }}
        type="button"
        className="btn btn-white btn-icon"
        data-bs-toggle="dropdown"
      >
        <MdMoreVert />
      </button>
      <div className="dropdown-menu">
        {childs.map((child, idx) => {
          if (child.path) {
            return (
              <Link to={child.path} key={idx} className="dropdown-item" onClick={() => child.action()}>
                {child.label}
              </Link>
            );
          }

          return (
            <button href={child.path} key={idx} className="dropdown-item" onClick={() => child.action()}>
              {child.label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default DropdownButton;
