import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "./styles";

import { formatValue, populateDates } from "../../utils";

import CustomChart from "./chart";
import Skeleton from "./skeleton";

import { getIncomes } from "services/api";
import { format } from "date-fns";

const DEFAULT_DATA = {
  amount: 0,
  range: ["7/6", "8/6", "9/6", "10/6", "11/6", "12/6", "13/6", "14/6"],
  completed: [0, 0, 0, 0, 0, 0, 0, 0],
  failed: [0, 0, 0, 0, 0, 0, 0, 0],
  refunded: [0, 0, 0, 0, 0, 0, 0, 0],
};

function TransactionsCard() {
  const block = useSelector((state) => state.general.block);
  const days = useSelector((state) => state.general.days);

  const [data, setData] = useState(DEFAULT_DATA);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchCardData() {
      setLoading(true);

      try {
        const incomes = await getIncomes(days, block.id);
        const dates = [];
        const values = {};

        const cardData = {
          amount: incomes.total_amount,
          range: [],
          completed: [],
          failed: [],
          refunded: [],
        };

        for (const key in incomes) {
          if (Object.hasOwnProperty.call(incomes, key) && key !== "total_amount") {
            values[key] = [];
            incomes[key].map((value) => {
              dates.push(format(new Date(value.date), "dd/MM"));
              values[key][format(new Date(value.date), "dd/MM")] = value.subscription_count;
              return value;
            });
          }
        }

        const datesSet = [...new Set(dates)];
        if (datesSet.length > 0) {
          const startDate = datesSet[0];
          const stopDate = datesSet[datesSet.length - 1];

          cardData.range = populateDates(startDate, stopDate);
          cardData.range.forEach((date) => {
            cardData.failed.push(values["fail_chart"][date] ? values["fail_chart"][date] : 0);
            cardData.completed.push(values["paid_chart"][date] ? values["paid_chart"][date] : 0);
            cardData.refunded.push(values["refunded_chart"][date] ? values["refunded_chart"][date] : 0);
          });
        } else {
          setData(DEFAULT_DATA);
          setLoading(false);
          return;
        }

        setData(cardData);
        setLoading(false);
      } catch (error) {
        setData(DEFAULT_DATA);
        setLoading(false);
      }
    }

    return fetchCardData();
  }, [block, days]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Card>
      <div className="card-body">
        <div className="subheader">Receita</div>

        <h3 style={{ color: "#232e3c", fontWeight: "bold" }}>{formatValue(data.amount)}</h3>

        <CustomChart data={data} />
      </div>
    </Card>
  );
}

export default TransactionsCard;
