import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #f4f6fa;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    border: none;
    box-shadow: rgb(35 46 60 / 8%) 0 2px 4px 0;
    padding: 8px;
    @media screen and (max-width: 991px) {
      width: 95%;
    }
  }
`;

export const RedirectInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  margin-top: 2rem;
  text-align: center;
  & > div {
    font-size: 20px;
    font-weight: bold;
    padding: 8px;
  }
  & > span:last-child {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 13px;
  }
`;

export const KdernoLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 2rem 0;
  & > h3 {
    font-size: 32px;
    line-height: 32px;
    margin: 0;
    margin-left: 8px;
  }
`;

export const KdernoSVG = styled.div`
  background: #55c9ff;
  padding: 8px;
  border-radius: 4px;
  & > svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Timer = styled.span`
  color: #55c9ff;
  font-weight: bold;
  font-size: 20px;
  /* margin-left: 8px; */
`;