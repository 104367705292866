import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSubscriptionsById, getTransactions } from "../../services/api";

export default function useLoadData() {
  const routerParams = useParams();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        const { data: _subscription } = await getSubscriptionsById({
          subscription_id: routerParams.id,
        });

        // Transações da Assinatura
        const { data: _transactions } = await getTransactions({
          subscription_id: _subscription.id,
        });

        let transactions = [];

        if (_transactions.results.length) {
          for (const transaction of _transactions.results) {
            transactions.push({
              status: transaction.status,
              id: transaction.id,
              date: transaction.created_at,
              payment_method: transaction.payment_method,
              amount: transaction.amount,
            });
          }
        }

        const API_RESPONSE = {
          id: _subscription.id,
          createdAt: _subscription.created_at,
          updatedAt: _subscription.updated_at,
          subscription: {
            plan: _subscription.plan_id,
            name: _subscription.user.public_name,
            charges: _subscription.charges,
            email: _subscription.user.email,
            payment_methods: [_subscription.payment_method],
            pagarme_url: _subscription.manage_url,
          },
          transactions,
        };

        setSubscriptionData(API_RESPONSE);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Falha ao buscar assinaturas");
      }
    }

    init();
  }, []);

  return {
    transactionData: subscriptionData,
    loading,
  };
}
