import React, { useEffect, useState } from "react";
import {
  Container,
  KdernoLogo,
  KdernoSVG,
  RedirectInfo,
  Timer,
} from "./styles";

function Redirect() {
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      window.location.href = 'http://kderno.com';
    }
  }, [timer]);

  return (
    <Container>
      <div className="card">
        <KdernoLogo>
          <KdernoSVG>
            <svg
              style={{ alignSelf: "center" }}
              width="17px"
              height="21px"
              viewBox="0 0 17 21"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>47F4A460-678F-4C9B-A8F2-912BD7173F10@1.00x</title>
              <desc>Created with sketchtool.</desc>
              <g
                id="Plataforma"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Login-/-Modal"
                  transform="translate(-495.000000, -182.000000)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <g id="Wrapper" transform="translate(482.000000, 150.000000)">
                    <g id="Group" transform="translate(0.000000, 24.000000)">
                      <polygon
                        id="kderno"
                        points="20.8339844 28.6035156 20.8339844 26.0878906 18.4003906 26.0878906 18.4003906 21.3574219 25.5917969 28.6035156 29.3242188 28.6035156 29.3242188 26.0878906 26.8085938 26.0878906 21.7363281 21.0429688 27.109375 16.5859375 29.3242188 16.5859375 29.3242188 14.234375 21.3671875 14.234375 21.3671875 16.5859375 23.6367188 16.5859375 18.4003906 20.9882812 18.4003906 8 13 8 13 10.4882812 15.5703125 10.4882812 15.5703125 26.0878906 13 26.0878906 13 28.6035156"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </KdernoSVG>
          <h3>Dashboard</h3>
        </KdernoLogo>

        <RedirectInfo>
          <div>Autenticação expirada</div>
          <span>
            Você será redirecionado para o <span>Kderno</span> em{" "}
            <Timer>{timer}s</Timer>
          </span>
          <span>
            Caso não seja automaticamente redirecionado{" "}
            <a href="https://kderno.com">clique aqui</a>.
          </span>
        </RedirectInfo>
      </div>
    </Container>
  );
}

export default Redirect;