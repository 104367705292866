import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    align-items: center;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
`;

export const Title = styled.h1`
    margin: 0px;
`;