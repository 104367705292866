export function setDays({ days }) {
  return {
    type: "@general/SET_DAYS",
    payload: { days },
  };
}

export function setBlock({ block }) {
  return {
    type: "@general/SET_BLOCK",
    payload: { block },
  };
}

export function setUserBlocks({ blocks }) {
  return {
    type: "@general/SET_USER_BLOCKS",
    payload: { blocks },
  };
}
