import React from "react";

import destructuringDate from "utils/destructuringDate";
import formatCountry from "utils/formatCountry";
import BlockKeyValue from "../../components/BlockKeyValue";

import { DetailsContainer } from "./styles";

export default function Details({ loading, customerData }) {
  return (
    <DetailsContainer>
      <section className="card">
        <div className="card-header">
          {loading ? (
            <div className="skeleton-line" style={{ width: "200px" }}></div>
          ) : (
            <strong>Detalhes do cliente</strong>
          )}
        </div>
        <div className="card-body">
          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="ID do Cliente"
              value={customerData?.id}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Total transacionado"
              value={"R$ " + customerData?.total_transacted}
              variant={3}
            />
          </div>

          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Cliente desde"
              value={
                destructuringDate(customerData?.createdAt).formatedDateShort
              }
              variant={5}
            />
          </div>
        </div>
      </section>

      {/* <hr /> */}

      <section className="card">
        <div className="card-header">
          {loading ? (
            <div className="skeleton-line" style={{ width: "200px" }}></div>
          ) : (
            <strong>Dados pessoais</strong>
          )}
        </div>
        <div className="card-body">
          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Nome"
              value={customerData?.name}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Telefone"
              value={customerData?.phone}
              variant={5}
            />
          </div>
          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="CPF / CNPJ"
              value={customerData?.document}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Email"
              value={customerData?.email}
              variant={5}
            />
          </div>
        </div>
      </section>

      {/* <hr /> */}

      <section className="card">
        <div className="card-header">
          {loading ? (
            <div className="skeleton-line" style={{ width: "200px" }}></div>
          ) : (
            <strong>Endereço</strong>
          )}
        </div>
        <div className="card-body">
          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Endereço"
              value={customerData?.address?.address}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Número"
              value={customerData?.address?.number}
              variant={5}
            />
          </div>

          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Complemento"
              value={customerData?.address?.complement}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Bairro"
              value={customerData?.address?.district}
              variant={5}
            />
          </div>

          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Cidade"
              value={customerData?.address?.city}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Estado"
              value={customerData?.address?.state}
              variant={5}
            />
          </div>

          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="País"
              value={formatCountry(customerData?.address?.country)}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="CEP"
              value={customerData?.address?.zip_code}
              variant={5}
            />
          </div>
        </div>
      </section>
    </DetailsContainer>
  );
}
