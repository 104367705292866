export function setProfile(profile) {
    return {
        type: '@user/SET_PROFILE',
        payload: { profile }
    }
}

export function setProfileLoading(loading) {
    return {
        type: '@user/SET_PROFILE_LOADING',
        payload: { loading }
    }
}