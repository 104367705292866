import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import classnames from 'classnames';

import { IconPackage } from '@tabler/icons';

import ControlledDropdown, { ControlledDropdownItem } from './ControlledDropdown';

export default function SalesMenuItem() {
    const location = useLocation();
    const [open, setOpen] = useState({
        dropdown: false,
        transactions: false,
        subscriptions: false,
        customers: false
    });

    useEffect(() => {
        const { pathname } = location;

        const transactionsOpen = (pathname === "/transactions");
        const subscriptionsOpen = (pathname === "/subscriptions");
        const dropdownOpen = transactionsOpen || subscriptionsOpen;

        setOpen({
            dropdown: dropdownOpen,
            transactions: transactionsOpen,
            subscriptions: subscriptionsOpen
        });
    }, [location]);

    return (
        <li className={classnames("nav-item dropdown", { ["dropup"]: open.dropdown })}>
            <a
                className="nav-link dropdown-toggle"
                onClick={() => setOpen(open => ({ ...open, dropdown: !open.dropdown }))}
                href="/navbar-base"
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded={open.dropdown}>
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <IconPackage />
                </span>

                <span className="nav-link-title"> Vendas </span>
            </a>

            <ControlledDropdown open={open.dropdown}>
                <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                        <ControlledDropdownItem
                            active={open.transactions}
                            to="/transactions">
                                Transações
                        </ControlledDropdownItem>

                        <ControlledDropdownItem
                            active={open.subscriptions}
                            to="/subscriptions">
                                Assinaturas
                        </ControlledDropdownItem>
                    </div>
                </div>
            </ControlledDropdown>
        </li>
    );
}