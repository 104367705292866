import Chart from "react-apexcharts";
import { Card } from "./styles";

function TransactionsCard({
  data = {
    range: ["", "", "", "", "", "", "", "", ""],
    test: [0, 80, 15, 45, 5, 72, 60, 23, 91],
  },
}) {
  const chartData = {
    options: {
      colors: ["#dfe2e7"],
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "left",
      },
      chart: {
        id: "Transações",
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        width: 8,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },

        categories: [...data.range],
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        show: false,
        labels: { show: false },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Tested",
        data: [...data.test],
      },
    ],
  };

  return (
    <Card>
      <div className="card-body">
        <div className="skeleton-line" style={{ maxWidth: "140px" }}></div>
        <div className="skeleton-heading" style={{ maxWidth: "200px" }}></div>

        <Chart options={chartData.options} series={chartData.series} width={"100%"} height={240} />
      </div>
    </Card>
  );
}

export default TransactionsCard;
