import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  width: calc(100% - 15rem);
  min-height: 72px;
  margin-left: 15rem;
  background-color: white;

  @media screen and (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 8px;
    margin-right: 8px;
  }

  & > *:first-child {
    margin-left: 0px;
  }

  & > *:last-child {
    margin-right: 0px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 991px) {
    & > #user-menu {
      display: none;
    }
  }
`;
