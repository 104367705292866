import styled, { createGlobalStyle } from "styled-components";

export const GlobalCss = createGlobalStyle`
  body {
    overflow: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;

  margin-left: 15rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  padding: 24px;
  background: #f4f6fa;
  border-radius: 16px;

  overflow-y: scroll;

  @media screen and (max-width: 991px) {
    margin-left: 0px;
    margin-right: 0px;
    padding: 8px;
  }
`;
