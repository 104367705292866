import React from "react";
import { Link } from "react-router-dom";
import { IconHome } from '@tabler/icons';

function HomeItem() {
    return (
        <li className="nav-item">
            <Link className="nav-link" to="/">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <IconHome />
                </span>

                <span className="nav-link-title">Visão Geral</span>
            </Link>
        </li>
    );
}

export default HomeItem;
