import { Link } from "react-router-dom";

export default function ControlledDropdown({ children, open }) {
    if(open)
        return (
            <div className="dropdown-menu show">
                {children}
            </div>
        );

    return (
        <div className="d-none">
            {children}
        </div>
    );
}

export function ControlledDropdownItem({ children, active, onClick, to }) {
    if(active)
        return (
            <Link to={to} className="dropdown-item active">
                { children }
            </Link>
        );

    return (
        <Link
            className="dropdown-item"
            onClick={onClick}
            to={to}>
                { children }
        </Link>
    );
}