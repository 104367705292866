import React from "react";
import { useSelector } from "react-redux";

import Loading from "./loading";

function UserMenu({ hideDesktop }) {
  const loading = useSelector((state) => state.user.profile.loading);
  const profile = useSelector((state) => state.user.profile.data);

  function handleLogout() {
    localStorage.removeItem("token");
    window.location.reload()
  }

  if (loading) return <Loading hideDesktop={hideDesktop} />;

  return (
    <div id="user-menu" className={`navbar-nav flex-row ${hideDesktop ? "d-lg-none" : ""}`}>
      <div className="nav-item d-none d-md-flex me-3"></div>

      <div className="nav-item dropdown">
        <a href="/" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
          <div className="d-none d-xl-block px-2">
            <div>{profile?.public_name}</div>
            <div className="mt-1 small text-muted">{profile?.email}</div>
          </div>

          <span
            className="avatar avatar-sm"
            style={{ backgroundImage: `url(${profile?.image || "https://www.w3schools.com/w3images/avatar2.png"})` }}
          />
        </a>

        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
          <span onClick={() => handleLogout()} style={{ cursor: "pointer" }} className="dropdown-item">
            Logout
          </span>
        </div>
      </div>
    </div>
  );
}

export default UserMenu;
