import React from "react";

import BlockKeyValue from "../../components/BlockKeyValue";

import { MoreDetailsTransactionContainer } from "./styles";

const PaymentType = {
  sale: "Venda",
  signature: "Assinatura",
};

export default function MoreDetailsTransaction({ loading, transactionData }) {
  return (
    <MoreDetailsTransactionContainer className="card">
      <div className="card-header">
        {loading ? (
          <div className="skeleton-line skeleton-line-full" />
        ) : (
          <strong>Detalhes da transação</strong>
        )}
      </div>

      <div className="card-body">
        <BlockKeyValue
          inline
          loading={loading}
          label="ID Transação"
          value={transactionData?.payment?.id}
          variant={1}
        />

        <BlockKeyValue
          inline
          loading={loading}
          label="Cupom"
          value={transactionData?.payment?.coupon}
          variant={1}
        />

        <BlockKeyValue
          inline
          loading={loading}
          label="Tipo"
          value={PaymentType[transactionData?.payment?.type]}
          variant={1}
        />
      </div>
    </MoreDetailsTransactionContainer>
  );
}
