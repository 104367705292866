import React from "react";

import { IconCaretUp, IconCaretDown, IconMinus } from "@tabler/icons";

import { Card, ListContainer } from "./styles";

export default function InfoCard({ resp, title, value, subtitle, percent, data }) {
  function getIcon(percent) {
    if (typeof percent !== "number") return;

    if (percent > 0) return <IconCaretUp className="text-green icon-filled signal-icon" />;

    if (percent < 0) return <IconCaretDown className="text-red icon-filled signal-icon" />;

    return <IconMinus className="text-gray signal-icon" />;
  }

  return (
    <Card resp={resp}>
      <div className="card-body">
          <div className="subheader">{title}</div>
      
            <ListContainer>
                {data.map((el, i) => (
                    <li key={i}>
                        <span className="li-label">{el.label}</span>
                        <div className="d-inline-flex align-items-center lh-1 li-value">
                        <span>{el.value}</span>
                        {getIcon(el.diff)}
                        </div>
                    </li>
                ))}
            </ListContainer>
      </div>
    </Card>
  );
}
