import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import Layout from "components/Layout";

import CustomTable from "components/Table";
import LoadingTable from "components/Table/loading";
import NameAndEmail from "components/Table/components/NameAndEmail";
import CustomDate from "components/Table/components/CustomDate";
import DropdownButton from "components/DropdownButton";
import BackwardAndTitle from "components/BackwardAndTitle";
import CSVButton from "../../components/CSVButton";

import { getTransactions } from "../../services/api";

import formatAmount from "utils/formatAmount";
import formatPaymentMethod from "utils/formatPaymentMethod";

const Status = (color) => {
  return <span className="badge badge-pill bg-blue" />;
};

const dropdownOptions = (data) => {
  const options = [
    {
      label: "Ver detalhes",
      path: `transactions/${data.id}`,
      action: () => {
        console.log("Ver detalhes");
      },
    },
    {
      label: "Estorno",
      action: () => {},
    },
    {
      label: "Link boleto",
      action: () => {
        window.open(data.boleto_url, "_blank");
      },
    },
    {
      label: "Enviar email",
      action: () => {
        console.log("Enviar email");
      },
    },
  ];

  if (data.type === "subscription") {
    options.push({
      label: "Detalhes da assinatura",
      action: () => {
        console.log("Detalhes da assinatura");
      },
    });
  }

  return options;
};

function Row({ data }) {
  const actions = dropdownOptions(data);

  return (
    <tr>
      <td style={{ padding: 16 }}>
        <Status />
      </td>
      <td>
        <span>#{data.id}</span>
      </td>
      <td>
        <NameAndEmail name={data.user.public_name} email={data.user.email} />
      </td>
      <td>
        <CustomDate date={data.date_created} />
      </td>
      <td>
        <span>{data.block.name}</span>
      </td>
      <td>
        <span>{formatAmount(data.amount)}</span>
      </td>
      <td>
        <span>{formatPaymentMethod(data.payment_method)}</span>
      </td>
      <td>
        <span>{data.coupon ? data.coupon.code : ""}</span>
      </td>
      <td>
        <span>{data.type}</span>
      </td>
      <td>
        <DropdownButton childs={actions} />
      </td>
    </tr>
  );
}

function Transactions() {
  const profile = useSelector((state) => state.user.profile.data);
  const block = useSelector((state) => state.general.block);
  const days = useSelector((state) => state.general.days);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [CSVData, setCSVData] = useState([]);

  const columns = [
    { field: "status", headerName: "" },
    { field: "authorization_code", headerName: "Cód." },
    { field: "user", headerName: "Nome" },
    { field: "created_at", headerName: "Data" },
    { field: "product", headerName: "Produto" },
    { field: "value", headerName: "Valor" },
    { field: "payment_method", headerName: "Forma de pgto." },
    { field: "coupon", headerName: "Cupom" },
    { field: "type", headerName: "Tipo" },
    { field: "actions", headerName: "" },
  ];

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        const { data: transactions } = await getTransactions({
          block_id: block?.id,
          page: currentPage,
          days,
        });

        const _rows = [];

        for (const [index, value] of transactions.results.entries()) {
          _rows.push(<Row data={value} key={index} />);
        }

        setCSVData(transactions.results);
        setRows(_rows);
        setPageCount(Math.ceil(transactions.count / 10));
        setLoading(false);
      } catch (error) {
        console.error("Falha ao buscar transações", error);
        setPageCount(1);
        setLoading(false);
      }
    }

    init();
  }, [profile, block, currentPage, days]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "2rem",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
          <BackwardAndTitle
            loading={loading}
            title="Transações"
            breadcrumb={[
              {
                href: "/",
                label: "Visão Geral",
              },
              {
                label: "Transações",
              },
            ]}
          />
          <CSVButton CSVData={CSVData} />
        </div>
        <div className="card">
          {loading ? (
            <LoadingTable />
          ) : (
            <CustomTable columns={columns} rows={rows} actions={5} />
          )}
          <div className="card-footer flex-end">
            <ReactPaginate
              previousLabel="Anterior"
              nextLabel="Próxima"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-end"
              activeClassName="active"
              pageClassName="page-item"
              nextClassName="page-link"
              pageLinkClassName="page-link"
              previousClassName="page-link"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Transactions;
