import React, { useState, useEffect } from "react";

import Table from "../../components/Table";
import TableLoading from "../../components/Table/loading";
import Row from "./TransactionsTableRow";

const columns = [
  { field: "status", headerName: "Status" },
  { field: "id", headerName: "ID" },
  { field: "date", headerName: "Data" },
  { field: "payment_method", headerName: "Forma Pgto" },
  { field: "amount", headerName: "Valor" },
  { field: "type", headerName: "Tipo" },
];

export default function TransactionTable({ loading, customerData }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (loading || !customerData) return;

    const updatedRows = [];

    for (const [index, values] of customerData.transactions.entries()) {
      updatedRows.push(<Row key={index} {...values} />);
    }

    setRows(updatedRows);
  }, [loading, customerData]);

  if (loading) return <TableLoading />;

  return (
    <div className="card">
      <Table columns={columns} rows={rows} />
    </div>
  );
}
