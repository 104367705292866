import React from "react";

import UserMenu from "../UserMenu";
import NavbarToggler from "./components/NavbarToggler";
import HomeItem from "./components/HomeItem";
import SalesMenuItem from "./components/SalesMenuItem";
import UserMenuItem from "./components/UserMenuItem";

import { Aside } from "./styles";


function Sidebar() {

    return (
        <Aside className="navbar navbar-vertical navbar-expand-lg show-passed">
            <div className="container-fluid">
                <NavbarToggler />

                <h1 className="navbar-brand navbar-brand-autodark">
                    <a href=".">
                        <img
                            src="https://storage.googleapis.com/kderno-files/kderno/files/2021/04/13/9160db12ad43_Horizontal_Copy.png"
                            alt="Kderno"
                            className="navbar-brand-image" />
                    </a>
                </h1>

                <UserMenu hideDesktop />

                <div className="collapse navbar-collapse" id="navbar-menu">
                    <ul className="navbar-nav pt-lg-3">
                        <HomeItem />
                        <SalesMenuItem />
                        <UserMenuItem />
                    </ul>
                </div>
            </div>
        </Aside>
    );
}

export default Sidebar;
