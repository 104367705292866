import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Layout from "components/Layout";

import CustomTable from "../../components/Table";
import LoadingTable from "../../components/Table/loading";
import BackwardAndTitle from "../../components/BackwardAndTitle";
import ReactPaginate from "react-paginate";

import { getFeedbacks } from "../../services/api";

function Row({ data }) {
  return (
    <tr>
      <td>
        <span>{data.user.public_name}</span>
      </td>
      <td>
        <span>{data?.notebook?.name}</span>
      </td>
      <td>
        <span>{data.question.text}</span>
      </td>
      <td>
        <span>{data?.data?.text}</span>
      </td>
      <td>
        <span>{data?.data?.rating ? `${data?.data?.rating}/5` : "-"}</span>
      </td>
    </tr>
  );
}

function Feedbacks() {
  const days = useSelector((state) => state.general.days);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    { field: "name", headerName: "Nome" },
    { field: "kderno", headerName: "Kderno" },
    { field: "question", headerName: "Pergunta" },
    { field: "answer", headerName: "Resposta" },
    { field: "score", headerName: "Nota" },
  ];

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        const { data: _feedbacks } = await getFeedbacks({
          page: currentPage,
          days,
        });
        const _rows = [];

        for (const [index, value] of _feedbacks.results.entries()) {
          _rows.push(<Row data={value} key={index} />);
        }

        setRows(_rows);
        setPageCount(Math.ceil(_feedbacks.count / 10));
        setLoading(false);
      } catch (error) {
        console.error("Falha ao buscar feedbacks", error);
        setLoading(false);
        setPageCount(1);
      }
    }

    return init();
  }, [currentPage, days]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "2rem",
          width: "100%",
        }}
      >
        <BackwardAndTitle
          loading={loading}
          title="Feedbacks"
          breadcrumb={[
            {
              href: "/",
              label: "Visão Geral",
            },
            {
              label: "Feedbacks",
            },
          ]}
        />
        <div className="card">
          {loading ? (
            <LoadingTable />
          ) : (
            <CustomTable columns={columns} rows={rows} />
          )}
          <div className="card-footer flex-end">
            <ReactPaginate
              previousLabel="Anterior"
              nextLabel="Próxima"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-end"
              activeClassName="active"
              pageClassName="page-item"
              nextClassName="page-link"
              pageLinkClassName="page-link"
              previousClassName="page-link"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Feedbacks;
