import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  .card-body {
    width: 70%;
  }

  @media screen and (max-width: 991px) {
    padding: 8px;
  }
`;

export const DetailsContainer = styled.div`
  margin: 40px 0px;

  & > * {
    margin-bottom: 2rem;
  }

  & > *:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    width: 70%;

    & > div {
      display: flex;

      & > div {
        flex: 1;
      }
    }
  }
`;
