import React from "react";

import Layout from "components/Layout";

import AmountDetails from "./AmountDetails";
import MoreDetailsTransaction from "./MoreDetailsTransaction";
import CustomerDetails from "./CustomerDetails";

import { Root, CustomBackwardAndTitle } from "./styles";

import useLoadData from "./useLoadData";

export default function TransactionDetails() {
  const { loading, transactionData } = useLoadData();

  return (
    <Layout>
      <Root>
        <CustomBackwardAndTitle
          title="Detalhes da transação"
          backHref="/transactions"
          breadcrumb={[
            {
              href: "/",
              label: "Visão Geral",
            },
            {
              href: "/transactions",
              label: "Transações",
            },
            {
              label: `#${transactionData?.transaction_id}`,
            },
          ]}
        />

        <AmountDetails loading={loading} transactionData={transactionData} />
        <MoreDetailsTransaction loading={loading} transactionData={transactionData} />
        <CustomerDetails loading={loading} transactionData={transactionData} />
      </Root>
    </Layout>
  );
}
