import React from 'react';
import styled from 'styled-components';
import { IconCircle as TblIconCircle } from '@tabler/icons';

import destructuringDate from '../../utils/destructuringDate';
import formatAmount from '../../utils/formatAmount';

import { PAYMENT_METHOD } from './index';

const IconCircle = styled(TblIconCircle)`
    height: 14px;
    /* width: 100%; */
    vertical-align: middle;
`;

const STATUS = {
    pending: <IconCircle fill="#939AA9" color="#939AA9" />,
    paid: <IconCircle fill="#1AB2A8" color="#1AB2A8" />
}

const TRANSACTION_TYPE = {
    subscription: "Assinatura",
    donation: "Doação",
    sale: "Venda"
}

export default function TransactionsTableRow({ status, id, date, payment_method, amount, type }) {
    const transactionDate = destructuringDate(date);

    return (
        <tr>
            <td>{STATUS[status]}</td>

            <td>#{id}</td>

            <td>
                {`${transactionDate.formatedDate} ${transactionDate.formatedTimeHM}h`}
            </td>
            
            <td>{PAYMENT_METHOD[payment_method]}</td>

            <td>{formatAmount(amount)}</td>

            <td>{TRANSACTION_TYPE[type]}</td>
        </tr>
    );
}