import React from "react";

import { Table, TableContainer } from "./styles";

function getPadding(rows, actions) {
  if (rows.length !== 1) {
    return "0px";
  }
  return `${40 * actions}px`;
}

export default function CustomTable({ columns, rows, actions = 1 }) {
  if (!columns.length || !rows.length) {
    return (
      <div className="empty">
        <div className="empty-icon"></div>
        <p className="empty-title">Nenhum resultado encontrado</p>
        <p className="empty-subtitle text-muted">Isso pode ser um erro, tente atualizar página ou alterar algum filtro.</p>
        <div className="empty-action">
          <button className="btn btn-primary" onClick={() => window.location.reload()}>
            Buscar Novamente
          </button>
        </div>
      </div>
    );
  }

  return (
    <TableContainer paddingBottom={getPadding(rows, actions)}>
      <Table className="table card-table table-vcenter datatable borderless">
        <thead>
          <tr>
            {columns.map((col, index) => {
              return (
                <th key={index}>
                  <span>{col.headerName}</span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{rows.map((row) => row)}</tbody>
      </Table>
    </TableContainer>
  );
}
