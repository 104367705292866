import React from "react";
import { GlobalCss, Container, Content } from "./styles";
import Sidebar from "../Sidebar";
import Header from "../Header";

function Layout({ children }) {
  return (
    <Container>
      <GlobalCss />

      <Sidebar />
      <Header />
      
      <Content>{children}</Content>
    </Container>
  );
}

export default Layout;
