import React, { useState } from "react";
import { TabbedContainer, Tab, Tabs } from "./styles";
import GraphCard from "../../../../components/GraphCard";
import { formatToPercentage, formatValue } from "../../utils";

export default function Tabbed({ data }) {
  const [value, setValue] = useState(0);

  return (
    <TabbedContainer>
      <Tabs>
        <Tab active={value === 0} onClick={() => setValue(0)}>
          Vendas
        </Tab>
        <Tab active={value === 1} onClick={() => setValue(1)}>
          Assinaturas
        </Tab>
        <Tab active={value === 2} onClick={() => setValue(2)}>
          Doações
        </Tab>
      </Tabs>
      {value === 0 && (
        <GraphCard
          resp
          title="Vendas"
          value={data.sales.total_sales}
          subtitle={formatValue(data.sales.total_value)}
          data={[
            { label: "Receita", value: formatValue(data.sales.total_income) },
            { label: "Reembolso", value: formatValue(data.sales.total_refunded) },
          ]}
          chartValues={data.sales.chartData}
        />
      )}
      {value === 1 && (
        <GraphCard
          title="Assinaturas"
          value={data.subscriptions.total_subscriptions}
          subtitle={formatValue(data.subscriptions.total_amount)}
          data={[
            { label: "% Cancelamento", value: formatToPercentage(data.subscriptions.total_canceled) },
            { label: "Inadimplência", value: formatValue(data.subscriptions.overdue) },
            { label: "Reembolso", value: formatToPercentage(data.subscriptions.refunded) },
            { label: "MRR", value: formatValue(data.subscriptions.MRR) },
          ]}
          chartValues={data.subscriptions.chartData}
        />
      )}
      {value === 2 && (
        <GraphCard
          resp
          title="Doações"
          value={data.donations.qty}
          subtitle={formatValue(data.donations.total)}
          percent={data.donations.variant}
          data={[
            { label: "Valor recebido", value: formatValue(data.donations.received.value) },
            { label: "% da meta", value: formatToPercentage(data.donations.goal_percent.value) },
            { label: "Reembolso", value: formatToPercentage(data.donations.refund.value) },
          ]}
          chartValues={data.donations.chartData}
        />
      )}
    </TabbedContainer>
  );
}
