import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import InfoCard from "../../../../components/InfoCard";
import Skeleton from "./skeleton";

import { formatTotals } from "../../utils";
import { getPerformance } from "services/api";

const DEFAULT_DATA = {
  total_sales: 0,
  clicks: 0,
  leads: 0,
};

function PerformanceCard() {
  const block = useSelector((state) => state.general.block);
  const days = useSelector((state) => state.general.days);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(DEFAULT_DATA);

  useEffect(() => {
    async function fetchCardData() {
      setLoading(true);

      try {
        const performanceData = await getPerformance(days, block.id);
        if (!performanceData) {
          throw new Error("empty data");
        }
        setData(performanceData);
        setLoading(false);
      } catch (error) {
        setData(DEFAULT_DATA);
        return setLoading(false);
      }
    }

    return fetchCardData();
  }, [block, days]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <InfoCard
      title="Desempenho"
      data={[
        { label: "Vendas", value: formatTotals(data.total_sales) },
        { label: "Cliques", value: formatTotals(data.clicks) },
        { label: "Leads", value: formatTotals(data.leads) },
      ]}
    />
  );
}

export default PerformanceCard;
