import { parse, format, addDays } from "date-fns";

export function formatValue(value) {
  if (!value) {
    return "R$0,00";
  }
  return `R$${value
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
}

export function formatTotals(value) {
  return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
}

export function formatToPercentage(value) {
  return `${(value * 100).toFixed(1)}%`;
}

export function populateDates(startDate, stopDate) {
  const _startDate = parse(startDate, "dd/MM", new Date());
  const _stopDate = parse(stopDate, "dd/MM", new Date());
  const dateArray = [];
  let currentDate = _startDate;
  while (currentDate <= _stopDate) {
    dateArray.push(format(new Date(currentDate), "dd/MM"));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}
