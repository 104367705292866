import styled from "styled-components";

import BackwardAndTitle from "../../components/BackwardAndTitle";
import BlockKeyValue from "../../components/BlockKeyValue";

// const ReactCreditCardHeight = 300;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  .card-body {
    width: 70%;
  }

  @media screen and (max-width: 991px) {
    padding: 8px;
  }
`;

export const CustomBackwardAndTitle = styled(BackwardAndTitle)`
  margin-bottom: 32px;
`;

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  & > :not(:first-child) {
    margin-left: 40px;
  }

  & > .card-body {
    display: flex;

    .rccs {
      display: flex !important;
      flex: 1 !important;
      align-items: center !important;
      justify-content: center !important;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 991px) {
    & > .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const AmountDetailsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

export const AmountCard = styled(BlockKeyValue)`
  margin: 24px 0px 12px 0px;
`;

export const DiscountRatesContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const MoreDetailsTransactionContainer = styled.div`
  display: inline-flex;
  flex-direction: column;

  margin-top: 1rem;
  .card-body {
    display: flex;
    flex-wrap: wrap;

    & > div {
      display: flex;

      & > div {
        flex: 1;
      }
    }
  }
`;

export const CustomerDetailsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 1rem;

  & > :not(:first-child) {
    margin-top: 16px;
  }

  .card-body {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;

      & > div {
        flex: 1;
      }
    }
  }
`;
