import React from "react";
import Chart from "react-apexcharts";

import { IconCaretUp, IconCaretDown, IconMinus } from "@tabler/icons";

import { Card, Graph, ListContainer } from "./styles";
import { formatToPercentage } from "../../pages/Overview/utils";

export default function GraphCard({ resp, title, value, subtitle, percent=0, data, chartValues }) {
  function getColor(percent) {
    if (percent > 0) return "text-green";

    if (percent < 0) return "text-red";

    return "text-yellow";
  }

  function getIcon(percent) {
    if (typeof percent !== "number") return;

    if (percent > 0) return <IconCaretUp className="text-green icon-filled signal-icon" />;

    if (percent < 0) return <IconCaretDown className="text-red icon-filled signal-icon" />;

    return <IconMinus className="text-gray signal-icon" />;
  }

  const chartData = {
    options: {
      colors: ["#206BC4"],
      legend: {
        show: true,
        position: "top",
      },

      chart: {
        toolbar: {
          show: true,
          offsetX: -10,
          offsetY: -80,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
        stroke: {
          curve: "smooth",
        },
        id: "Transações",
        type: "area",
        tooltip: {
          enabled: false,
          enabledOnSeries: false,
        },
      },
      xaxis: {
        categories: [...chartValues.range],
        show: false,
        labels: { show: false },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        categories: [...chartValues.range],
        show: false,
        labels: { show: false },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        padding: { left: 0, right: 0 },
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "smooth",

        width: 2,
      },
    },
    series: [
      {
        name: "Vendas",
        type: "area",
        data: [...chartValues.completed],
      },
    ],
  };

  return (
    <Card resp={resp}>
      <div className="card-body">
        {!resp && <div className="subheader">{title}</div>}

        <div className="d-flex align-items-baseline">
          <div className="h1 mb-0 me-2">{value}</div>

          <div className="me-auto percentage">
            <span className={`${getColor(percent)} "d-inline-flex align-items-center lh-1"`}>
              {formatToPercentage(percent)}

              {percent > 0 ? <IconCaretUp className="icon-filled signal-icon" /> : <IconCaretDown className="icon-filled signal-icon" />}
            </span>
          </div>
        </div>

        {subtitle && <span style={{ color: "#757C85" }}>{subtitle}</span>}
      </div>

      <Graph>
        <Chart options={chartData.options} series={chartData.series} type={"area"} width={"100%"} height={120} />
      </Graph>

      {data && (
        <ListContainer>
          {data.map((el, i) => (
            <li key={i}>
              <span className="li-label">{el.label}</span>
              <div className="d-inline-flex align-items-center lh-1 li-value">
                <span>{el.value}</span>
                {getIcon(el.diff)}
              </div>
            </li>
          ))}
        </ListContainer>
      )}
    </Card>
  );
}
