import React from "react";

import { Row } from "../../styles";
import { DonutValues } from "./styles";
import Chart from "react-apexcharts";

function DonutChart() {
  const chartConfig = {
    series: [10],
    options: {
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
          },
        },
      },
      colors: ["#dfe2e7", "#888"],
      legend: {
        show: false,
      },
      chart: {
        type: "donut",
        offsetX: -16,
        height: 400,
      },
      labels: [""],

      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <div className="card-body" style={{ position: "relative", borderTop: "none", paddingTop: 0 }}>
      <div className="skeleton-line" style={{ maxWidth: "120px" }}></div>
      <Chart options={chartConfig.options} series={chartConfig.series} type="donut" width={"120"} height={120} />
      <DonutValues>
        <div className="skeleton-line" style={{ maxWidth: "180px" }}></div>
        <div className="skeleton-line" style={{ maxWidth: "180px" }}></div>
        <div className="skeleton-line" style={{ maxWidth: "180px" }}></div>
      </DonutValues>
    </div>
  );
}

function InfoSkeleton() {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", minWidth: "300px" }}>
      <div className="card" style={{ overflow: "visible" }}>
        <div className="card-body">
          <div className="skeleton-line" style={{ maxWidth: "200px" }}></div>
          <div className="skeleton-heading" style={{ maxWidth: "180px" }}></div>
        </div>
        <div className="card-body">
          <Row>
            <div className="col-md-6" style={{ flex: 1 }}>
              <div>
                <div className="skeleton-line" style={{ maxWidth: "120px" }}></div>
                <div className="skeleton-heading" style={{ maxWidth: "180px" }}></div>
              </div>
            </div>
            <div className="col-md-6" style={{ flex: 1 }}>
              <div>
                <div className="skeleton-line" style={{ maxWidth: "120px" }}></div>
                <div className="skeleton-heading" style={{ maxWidth: "180px" }}></div>
              </div>
            </div>
          </Row>
        </div>
        <DonutChart />
      </div>
    </div>
  );
}

export default InfoSkeleton;
