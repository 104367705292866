import React from "react";

import Layout from "components/Layout";

import PageTitle from "./PageTitle";
import MoreDetails from "./MoreDetails";
import TransactionsTable from "./TransactionsTable";

import useLoadData from "./useLoadData";

import { Container } from "./styles";

export const PAYMENT_METHOD = {
  credit_card: "Cartão de crédito",
  boleto: "Boleto",
};

export default function SubscriptionsDetails() {
  const { loading, transactionData } = useLoadData();

  return (
    <Layout>
      <Container>
        <PageTitle loading={loading} transactionData={transactionData} />
        <MoreDetails loading={loading} transactionData={transactionData} />
        <div className="card">
          <TransactionsTable loading={loading} transactionData={transactionData} />
        </div>
      </Container>
    </Layout>
  );
}
