import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { setBlock, setUserBlocks } from "store/modules/general/actions";

import api, { getProfile, getAllBlocks } from "services/api";
import { setProfileLoading, setProfile } from "store/modules/user/actions";

export default function useImpersonate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  async function impersonate() {
    const authToken = token || localStorage.getItem("token");

    try {
      dispatch(setProfileLoading(true));

      const profile = await getProfile();

      dispatch(setProfile(profile));

      const _allBlocks = await getAllBlocks();

      dispatch(
        setUserBlocks({
          blocks: [..._allBlocks],
        })
      );

      if (_allBlocks.length) {
        dispatch(
          setBlock({
            block: { name: 'Todos os blocos' },
          })
        );
      }

      if (localStorage.getItem("token")) return;

      localStorage.setItem("token", authToken);

      history.push("/");
    } catch (err) {
      throw err;
    } finally {
      dispatch(setProfileLoading(false));
    }
  }

  function failureImpersonate() {
    localStorage.removeItem("token");

    history.push("/redirect");
  }

  useEffect(() => {
    const authToken = token || localStorage.getItem("token");

    if (authToken);
    api.defaults.headers.Authorization = "Bearer " + authToken;

    async function checkAuthToken() {
      try {
        await impersonate();
      } catch (err) {
        failureImpersonate();
      }
    }

    checkAuthToken();
  }, []);
}
