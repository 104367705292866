import Chart from "react-apexcharts";

function CustomChart({ data }) {
  const chartData = {
    options: {
      colors: ["#2FB344", "#BBB", "#FF0000"],
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "left",
      },
      chart: {
        id: "Transações",
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        width: 3,
      },
      xaxis: {
        categories: [...data.range],
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        show: false,
        labels: { show: false },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Efetuadas",
        data: [...data.completed],
      },
      {
        name: "Com Falhas",
        data: [...data.failed],
      },
      {
        name: "Estornadas",
        data: [...data.refunded],
      },
    ],
  };

  return <Chart options={chartData.options} series={chartData.series} width={"100%"} height={220} />;
}

export default CustomChart;