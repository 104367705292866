import React from "react";

import { IconArrowUpCircle as GoUpIcon, IconArrowDownCircle as GoDownIcon } from "@tabler/icons";

import { Root, Border, Key, Value, Percent } from "./styles";

function PercentageValue({ value }) {
  const goUp = value >= 0;

  return (
    <Percent className="percent" goUp={goUp}>
      {goUp ? (
        <>
          <GoUpIcon/>{` ${value}%`}
        </>
      ) : (
        <>
          <GoDownIcon/>{` ${value}%`}
        </>
      )}
    </Percent>
  );
}

export default function BlockKeyValue({ className, inline, loading, label, value, percent, variant, bTop, bLeft, bBottom, bRight }) {
  if (loading)
    return (
      <Root className={className} inline={inline}>
        <div className="skeleton-line skeleton-line-full" />
        <div className="skeleton-line skeleton-line-full" />
      </Root>
    );

  return (
    <Root className={className} inline={inline} variant={variant} bTop={bTop} bLeft={bLeft} bBottom={bBottom} bRight={bRight}>
      <Key className="key" dangerouslySetInnerHTML={{ __html: label }} />
      <Value className="value">
        <span dangerouslySetInnerHTML={{ __html: value }} />

        {percent && <PercentageValue value={percent} />}
      </Value>

      {bTop && <Border className="b-top" />}
      {bLeft && <Border className="b-left" />}
      {bBottom && <Border className="b-bottom" />}
      {bRight && <Border className="b-right" />}
    </Root>
  );
}
