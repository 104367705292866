import styled from "styled-components";

export const Card = styled.div.attrs((props) => ({
  className: !props.resp && "card",
}))`
  min-width: 300px;
  flex: 1;
  height: auto;
  max-height: 400px;

  & .card-body {
    padding: 1rem 1rem 0rem 1rem;
  }

  & .signal-icon {
    width: 16px;
    height: 16px;
  }

  & .text-gray {
    color: #999;
  }

  & .percentage {
    font-size: 12px;
  }
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 16px 0px;
  max-height: 280px;
  overflow-y: hidden;
  overflow-x: hidden;

  &:hover {
    overflow-y: auto;
  }

  & > li {
    display: flex;
    padding: 8px 0px;
    font-size: 13px;
    color: #232e3c;

    & > *:first-child {
      flex: 1;
    }
  }

  & > li:not(:last-child) {
    border-bottom: 1.5px dashed #e8e9f1;
  }

  & .li-label {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  }

  & .li-value {
    font-weight: 400;
    gap: 6px;
    -webkit-font-smoothing: antialiased;
  }
`;
