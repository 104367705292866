import styled, { css } from "styled-components";

const CustomCSS = css`
  .card {
    margin-bottom: 24px !important;
    flex: 1;
    overflow: hidden;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${CustomCSS};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 48px 0px;
`;

export const CardBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.splitted && "space-between"};

  & > * {
    max-height: ${(props) => props.splitted && "140px"};
  }
`;

export const FillContainer = styled.div`
  flex: 1;
  padding: ${(props) => (props.padding ? props.padding : "20px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Valores = styled.div`
  display: flex;
  justify-content: center;
  & > * {
    flex: 1;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding-left: 16px;
    padding-right: 16px;
  }

  & > *:first-child {
    border-left: none;
    padding-left: 0px;
  }

  & > *:last-child {
    border-right: none;
    border-left: none;
    padding-right: 0px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: ${({ main }) => main && "center"};
  flex-wrap: ${({ nowrap }) => !nowrap && "wrap"};

  padding: ${({ padding }) => padding && padding};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};

  .card {
    border-radius: 8px;
    border: none;
  }

  & > * {
    margin-right: ${(props) => props.gap && props.gap};
    margin-top: ${(props) => props.margin && props.margin};
    margin-bottom: ${(props) => props.margin && props.margin};
  }

  & > *:first-child {
    margin-left: 0px;
  }

  & > *:last-child {
    margin-right: 0px;
  }
`;

export const Col = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  padding: ${(props) => props.main && "0px 0.5rem"};
  align-items: ${(props) => props.align && props.align};

  & > * {
    margin-bottom: ${(props) => props.gap && props.gap};
  }

  & > *:first-child {
    margin-top: 0px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }
`;

export const RowTitle = styled.h3`
  margin: 0px 0px 8px 8px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChartContainer = styled.div`
  width: 100%;
  max-width: 300px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-self: center;
`;
