import { DonutValues } from "./styles";
import Chart from "react-apexcharts";

function DonutLabel({ label = "", value = "", color = "#ccc" }) {
  return (
    <div className="donut-label-container">
      <label>
        <div className="label-color" style={{ background: color }}></div>
        <span>{label}</span>
      </label>
      <span className="donut-value">{value}</span>
    </div>
  );
}

function DonutChart({ data }) {
  const colours = ["#2FB344", "#42A5F5", "#ccc"];
  const chartConfig = {
    series: [...data.values],
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
          },
        },
      },
      colors: [...colours],
      legend: {
        show: false,
      },
      chart: {
        type: "donut",
        offsetX: -16,
        height: 400,
      },
      labels: [...data.labels],

      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <div className="card-body" style={{ position: "relative", borderTop: "none", paddingTop: 0 }}>
      <div className="subheader">Arrecadação</div>
      <Chart options={chartConfig.options} series={chartConfig.series} type="donut" width={"120"} height={120} />
      <DonutValues>
        {data.values.map((value, idx) => (
          <DonutLabel key={idx} label={data.labels[idx]} value={value} color={colours[idx]} />
        ))}
      </DonutValues>
    </div>
  );
}

export default DonutChart;