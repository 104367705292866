import React from "react";
import ReactDOM from "react-dom";

import "react-credit-cards/es/styles-compiled.css";
import "./styles/global.css";

import { Provider } from "react-redux";
import { initStore } from './store';

import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={initStore}>
    <App />
  </Provider>,
  rootElement
);
