import React from "react";
import { Switch, Route } from "react-router-dom";

import Overview from 'pages/Overview';
import Transactions from 'pages/Transactions';
import TransactionDetails from 'pages/TransactionDetails';
import Subscriptions from "pages/Subscriptions";
import SubscriptionsDetails from "pages/SubscriptionsDetails";
import Customers from "pages/Customers";
import CustomersDetails from "pages/CustomersDetails";
import Leads from "pages/Leads";
import Feedbacks from "pages/Feedbacks";
import Redirect from "pages/Redirect";

import useImpersonate from 'utils/useImpersonate';

function ImpersonateRoute ({ component: Component, ...rest}) {
    useImpersonate();

    return (
      <Route
        {...rest}
        render={(props) => <Component {...props} />}
      />
    )
  }

const Routes = () => (
  <Switch>
    <ImpersonateRoute path="/" exact component={Overview}></ImpersonateRoute>
    <ImpersonateRoute exact path="/transactions" component={Transactions}></ImpersonateRoute>
    <ImpersonateRoute path="/transactions/:id" component={TransactionDetails}></ImpersonateRoute>
    <ImpersonateRoute exact path="/subscriptions" component={Subscriptions}></ImpersonateRoute>
    <ImpersonateRoute path="/subscriptions/:id" component={SubscriptionsDetails}></ImpersonateRoute>
    <ImpersonateRoute exact path="/customers" component={Customers}></ImpersonateRoute>
    <ImpersonateRoute path="/customers/:id" component={CustomersDetails}></ImpersonateRoute>
    <ImpersonateRoute path="/leads" component={Leads}></ImpersonateRoute>
    <ImpersonateRoute path="/feedbacks" component={Feedbacks}></ImpersonateRoute>
    <ImpersonateRoute path="/redirect" component={Redirect}></ImpersonateRoute>
  </Switch>
);

export default Routes;
