import React from 'react';

import BlockKeyValue from '../../components/BlockKeyValue';

import { DiscountRatesContainer } from './styles';

function DiscountRatesBlock({ loading, label, payment_amount, discount, ...props }) {
    const labelPercent = String(discount).replace(".", ",");

    function calc() {
        if(!payment_amount || !discount)
            return;

        const result = Number((payment_amount * discount) / 100).toFixed(2);

        return String(result).replace(".", ",");
    }

    return (
        <BlockKeyValue
            {...props}    
            inline
            loading={loading}
            label={label}
            value={
                `<strong>R$ ${calc()}</strong><br/>` +
                `${labelPercent}%`
            } />
    );
}

export default function DiscountRates({ loading, transactionData }) {
    return (
        <DiscountRatesContainer>
            <DiscountRatesBlock
                loading={loading}
                label="Taxas financeiras"
                payment_amount={transactionData?.payment?.amount}
                discount={transactionData?.payment?.discount_rates?.financial_fees}
                bRight />

            <DiscountRatesBlock
                loading={loading}
                label="% Kderno"
                payment_amount={transactionData?.payment?.amount}
                discount={transactionData?.payment?.discount_rates?.kderno}
                bRight />

            <DiscountRatesBlock
                loading={loading}
                label="Você (Líquido)"
                payment_amount={transactionData?.payment?.amount}
                discount={
                    100 - (transactionData?.payment?.discount_rates?.financial_fees) - (transactionData?.payment?.discount_rates?.kderno)
                } />
        </DiscountRatesContainer>
    );
}