import React, { useState, useEffect } from "react";

import Layout from "components/Layout";

import { Container, Content, Row, Col } from "./styles";

import BackwardAndTitle from "../../components/BackwardAndTitle";
import {
  AccessesCard,
  BoletoCard,
  InfoCard,
  PerformanceCard,
  SalesCard,
  TransactionsCard,
} from "./Cards";

function Overview() {
  const [windowWidth, setWindowWidth] = useState(1366);

  useEffect(() => {
    let timeoutID = null;

    function updateSize() {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        setWindowWidth(document.body.clientWidth);
      }, 200);
    }
    updateSize();

    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <Layout>
      <Container>
        <BackwardAndTitle title="Visão Geral" />
        <Content>
          <Row main maxWidth={"2000px"}>
            <Col main flex={1}>
              <InfoCard />
            </Col>
            <Col main flex={2}>
              <TransactionsCard />
            </Col>
          </Row>

          <Row main maxWidth={"2000px"}>
            <Col main={windowWidth <= 768} flex={1}>
              <SalesCard width={windowWidth} />
            </Col>
          </Row>

          <Row main maxWidth={"2000px"}>
            <Col main>
              <PerformanceCard />
            </Col>
            <Col main>
              <BoletoCard />
            </Col>

            <Col main flex={2}>
              <AccessesCard />
            </Col>
          </Row>
        </Content>
      </Container>
    </Layout>
  );
}

export default Overview;
