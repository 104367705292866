import React from "react";

import BlockKeyValue from "../../components/BlockKeyValue";
import formatCountry from "utils/formatCountry";

import { CustomerDetailsContainer } from "./styles";

export default function CustomerDetails({ loading, transactionData }) {
  return (
    <CustomerDetailsContainer>
      <div className="card">
        <div className="card-header">
          {loading ? (
            <div className="skeleton-line skeleton-line-full" />
          ) : (
            <strong>Detalhes do cliente</strong>
          )}
        </div>
        <div className="card-body">
          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Nome"
              value={transactionData?.customer?.name}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Telefone"
              value={transactionData?.customer?.phone}
              variant={5}
            />
          </div>
          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="CPF/CNPJ"
              value={transactionData?.customer?.document}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Email"
              value={transactionData?.customer?.email}
              variant={5}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          {loading ? (
            <div className="skeleton-line skeleton-line-full" />
          ) : (
            <strong>Endereço do cliente</strong>
          )}
        </div>
        <div className="card-body">
          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Endereço"
              value={transactionData?.customer?.address?.address}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Número"
              value={transactionData?.customer?.address?.number}
              variant={5}
            />
          </div>

          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Complemento"
              value={transactionData?.customer?.address?.complement}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Bairro"
              value={transactionData?.customer?.address?.district}
              variant={5}
            />
          </div>

          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="Cidade"
              value={transactionData?.customer?.address?.city}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="Estado"
              value={transactionData?.customer?.address?.state}
              variant={5}
            />
          </div>

          <div>
            <BlockKeyValue
              inline
              loading={loading}
              label="País"
              value={formatCountry(transactionData?.customer?.address?.country)}
              variant={5}
            />

            <BlockKeyValue
              inline
              loading={loading}
              label="CEP"
              value={transactionData?.customer?.address?.zip_code}
              variant={5}
            />
          </div>
        </div>
      </div>
    </CustomerDetailsContainer>
  );
}
