import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { IconCaretUp, IconCaretDown } from "@tabler/icons";

import { Card } from "./styles";
import { formatToPercentage, populateDates } from "../../utils";

import CustomChart from "./chart";
import Skeleton from "./skeleton";

import { getVisits } from "services/api";

const DEFAULT_DATA = {
  total_visits: 0,
  diff: 0,
  range: ["7/6", "8/6", "9/6", "10/6", "11/6", "12/6", "13/6", "14/6"],
  accesses: [0, 0, 0, 0, 0, 0, 0, 0],
};

function AccessesCard() {
  function getColor(percent) {
    if (percent > 0) return "text-green";

    if (percent < 0) return "text-red";

    return "text-yellow";
  }

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(DEFAULT_DATA);

  const block = useSelector((state) => state.general.block);
  const days = useSelector((state) => state.general.days);

  useEffect(() => {
    async function fetchCardData() {
      setLoading(true);

      try {
        const visits = await getVisits(days, block.id);
        const dates = [];
        const values = {};

        const cardData = {
          total_visits: visits.total_visits,
          diff: 0,
          range: [],
          accesses: [],
        };

        if (visits.chart_info.length > 0) {
          visits.chart_info.map((value) => {
            values[format(new Date(value.date), "dd/MM")] = value.visit_count;
            dates.push(format(new Date(value.date), "dd/MM"));
            return value;
          });
        } else {
          throw new Error("invalid data");
        }

        const datesSet = [...new Set(dates)];
        if (datesSet.length > 0) {
          const startDate = datesSet[0];
          const stopDate = datesSet[datesSet.length - 1];

          cardData.range = populateDates(startDate, stopDate);
          cardData.range.forEach((date) => {
            cardData.accesses.push(values[date] ? values[date] : 0);
          });
        } else {
          throw new Error("invalid data");
        }

        setData(cardData);
        setLoading(false);
      } catch (error) {
        setData(DEFAULT_DATA);
        setLoading(false);
      }
    }

    return fetchCardData();
  }, [days, block]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Card>
      <div className="card-body">
        <div className="subheader">Acessos</div>

        <div className="d-flex align-items-baseline">
          <div className="h1 mb-0 me-2">{data.total_visits}</div>

          <div className="me-auto percentage">
            <span className={`${getColor(data.diff)} "d-inline-flex align-items-center lh-1"`}>
              {formatToPercentage(data.diff)}

              {data.diff > 0 ? <IconCaretUp className="icon-filled signal-icon" /> : <IconCaretDown className="icon-filled signal-icon" />}
            </span>
          </div>
        </div>

        <span style={{ color: "#757C85" }}>{"Hoje"}</span>

        <CustomChart data={data} />
      </div>
    </Card>
  );
}

export default AccessesCard;
