import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MainCard } from "./styles";
import GraphCard from "../../../../components/GraphCard";
import { formatToPercentage, formatValue } from "../../utils";
import Responsive from "./responsive";
import Skeleton from "./skeleton";

import { format } from "date-fns";

import { getSales, getPaymentSubscriptions, getDonations } from "services/api";

const DEFAULT_CHART_VALUES = {
  range: ["7/6", "8/6", "9/6", "10/6", "11/6", "12/6", "13/6", "14/6", "15/6", "16/6", "17/6"],
  completed: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};

const DEFAULT_DATA = {
  sales: {
    total_sales: 0,
    total_value: 0,
    total_income: 0,
    total_refunded: 0,
    variation: 0,
    chartData: DEFAULT_CHART_VALUES,
  },
  subscriptions: {
    total_subscriptions: 0,
    total_amount: 0,
    refunded: 0,
    total_canceled: 0,
    MRR: 0,
    overdue: 0,
    variation: 0,
    chartData: DEFAULT_CHART_VALUES,
  },
  donations: {
    qty: 0,
    total: 0,
    variant: 0,
    received: { value: 0, diff: 0 },
    goal_percent: { value: 0 },
    refund: { value: 0 },
    chartData: DEFAULT_CHART_VALUES,
  },
};

function SalesCard({ width }) {
  const [data, setData] = useState(DEFAULT_DATA);
  const [loading, setLoading] = useState(true);

  const block = useSelector((state) => state.general.block);
  const days = useSelector((state) => state.general.days);

  useEffect(() => {
    async function fetchCardData() {
      setLoading(true);

      try {
        const cardData = { ...DEFAULT_DATA };

        const salesData = await getSales(days, block.id);
        if (salesData) {
          cardData.sales = salesData;
          cardData.sales.chartData = {
            range: salesData.chart_info.map((value) => format(new Date(value.date), "dd/MM")),
            completed: salesData.chart_info.map((value) => value.subscription_transaction_count),
          };
        } else {
          cardData.sales = DEFAULT_DATA.sales;
          cardData.sales.chartData = DEFAULT_CHART_VALUES;
        }

        const subscriptionsData = await getPaymentSubscriptions(days, block.id);
        if (subscriptionsData) {
          cardData.subscriptions = subscriptionsData;
          cardData.subscriptions.chartData = {
            range: subscriptionsData.chart_info.map((value) => format(new Date(value.date), "dd/MM")),
            completed: subscriptionsData.chart_info.map((value) => value.subscription_count),
          };
        } else {
          cardData.subscriptions = DEFAULT_DATA.subscriptions;
          cardData.subscriptions.chartData = DEFAULT_CHART_VALUES;
        }

        const donationsData = await getDonations(days, block.id);
        if (donationsData) {
          cardData.donations = donationsData;
          cardData.donations.chartData = {
            range: donationsData.chart_info.map((value) => format(new Date(value.date), "dd/MM")),
            completed: donationsData.chart_info.map((value) => value.subscription_transaction_count),
          };
        } else {
          cardData.donations = DEFAULT_DATA.donations;
          cardData.donations.chartData = DEFAULT_CHART_VALUES;
        }

        setData(cardData);
        setLoading(false);
      } catch (error) {
        setData(DEFAULT_DATA);
        setLoading(false);
      }
    }

    return fetchCardData();
  }, [block, days]);

  if (loading) {
    return <Skeleton />;
  }

  if (width <= 768) {
    return <Responsive data={data} />;
  }

  return (
    <MainCard margin="8px" gap="8px">
      <GraphCard
        title="Vendas"
        value={data.sales.total_sales}
        subtitle={formatValue(data.sales.total_value)}
        data={[
          { label: "Receita", value: formatValue(data.sales.total_income) },
          { label: "Reembolso", value: formatValue(data.sales.total_refunded) },
        ]}
        chartValues={data.sales.chartData}
      />
      <GraphCard
        title="Assinaturas"
        value={data.subscriptions.total_subscriptions}
        subtitle={formatValue(data.subscriptions.total_amount)}
        data={[
          { label: "% Cancelamento", value: formatToPercentage(data.subscriptions.total_canceled) },
          { label: "Inadimplência", value: formatValue(data.subscriptions.overdue) },
          { label: "Reembolso", value: formatToPercentage(data.subscriptions.refunded) },
          { label: "MRR", value: formatValue(data.subscriptions.MRR) },
        ]}
        chartValues={data.subscriptions.chartData}
      />
      <GraphCard
        title="Doações"
        value={data.donations.qty}
        subtitle={formatValue(data.donations.total)}
        percent={data.donations.variant}
        data={[
          { label: "Valor recebido", value: formatValue(data.donations.received.value) },
          { label: "% da meta", value: formatToPercentage(data.donations.goal_percent.value) },
          { label: "Reembolso", value: formatToPercentage(data.donations.refund.value) },
        ]}
        chartValues={data.donations.chartData}
      />
    </MainCard>
  );
}

export default SalesCard;
