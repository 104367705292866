import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBlock, setDays } from "store/modules/general/actions";

function FilterButtons() {
  const dispatch = useDispatch();

  const daysOptions = useSelector((state) => state.general.daysOptions);
  const userBlocks = useSelector((state) => state.general.userBlocks);

  const block = useSelector((state) => state.general.block);
  const days = useSelector((state) => state.general.days);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div className="dropdown">
        <button
          type="button"
          className="btn btn-ghost-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <span
            style={{
              cursor: "pointer",
              maxWidth: 300,
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            {block.name}
          </span>
        </button>
        <div
          className="dropdown-menu"
          style={{ maxHeight: "260px", overflowY: "scroll" }}
        >
          <div
            key={`block-all`}
            title={`Todos os blocos`}
            className="dropdown-item"
            style={{
              cursor: "pointer",
              maxWidth: 300,
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "inline-block",
            }}
            onClick={() =>
              dispatch(setBlock({ block: { name: "Todos os blocos" } }))
            }
          >
            Todos os blocos
          </div>
          {userBlocks.length > 0 &&
            userBlocks.map((block, index) => (
              <div
                key={`block-${index}`}
                title={`Bloco ${block.id}`}
                className="dropdown-item"
                style={{
                  cursor: "pointer",
                  maxWidth: 300,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "inline-block",
                }}
                onClick={() => dispatch(setBlock({ block }))}
              >
                {`${block.name}`}
              </div>
            ))}
        </div>
      </div>
      <div className="dropdown">
        <button
          type="button"
          className="btn btn-ghost-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          Últimos {days} dias
        </button>
        <div className="dropdown-menu">
          {daysOptions.length > 0 &&
            daysOptions.map((day, index) => (
              <div
                key={`day-${index}`}
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(setDays({ days: day }))}
              >
                Últimos {day} dias
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default FilterButtons;
