export default function NavbarToggler() {
    return (
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-menu">
              <span className="navbar-toggler-icon" />
        </button>
    );
}