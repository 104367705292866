const INITIAL_STATE = {
  profile: {
    loading: true,
    data: null,
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case "@user/SET_PROFILE_LOADING":
      state.profile.loading = payload.loading;
      break;
    case "@user/SET_PROFILE":
      state.profile.data = payload.profile;
      break;
    default:
        return { ...state };
  }

  return { ...state };
}
