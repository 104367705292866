import { MainCard } from "./styles";
import GraphSkeleton from "../../../../components/GraphCard/skeleton";
import { TabbedContainer, Tab, Tabs } from "./styles";

function SalesCard({ width }) {
  if (width <= 768) {
    return (
      <TabbedContainer>
        <Tabs>
          <Tab>
            <div className="skeleton-line" style={{ width: "100%" }}></div>
          </Tab>
          <Tab>
            <div className="skeleton-line" style={{ width: "100%" }}></div>
          </Tab>
          <Tab>
            <div className="skeleton-line" style={{ width: "100%" }}></div>
          </Tab>
        </Tabs>
        <GraphSkeleton resp />
      </TabbedContainer>
    );
  }
  return (
    <MainCard margin="8px" gap="8px">
      <GraphSkeleton />
      <GraphSkeleton />
      <GraphSkeleton />
    </MainCard>
  );
}

export default SalesCard;
