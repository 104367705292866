import Chart from "react-apexcharts";

function CustomChart({ data }) {
  const chartData = {
    options: {
      colors: ["#206BC4"],
      legend: {
        show: true,
        position: "top",
      },

      chart: {
        stroke: {
          curve: "smooth",
        },
        id: "Acessos",
        type: "area",
        toolbar: {
          show: false,
        },
        tooltip: {
          enabled: false,
          enabledOnSeries: false,
        },
      },
      xaxis: {
        categories: [...data.range],
        show: true,
        labels: { show: true },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        categories: [...data.range],
        show: false,
        labels: { show: false },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        padding: { left: 0, right: 0 },
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Acessos",
        type: "area",
        data: [...data.accesses],
      },
    ],
  };

  return <Chart options={chartData.options} series={chartData.series} width={"100%"} height={120} />;
}

export default CustomChart;