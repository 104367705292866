import React from 'react';
import classNames from 'classnames';

export default function UserMenuLoading({ hideDesktop }) {
    return (
        <div
            id="user-menu"
            className={classNames("navbar-nav flex-row d-flex align-items-center", {
                "d-lg-none": hideDesktop
            })}>
                <div className="d-none d-xl-block px-2" style={{ minWidth: '130px' }}>
                    <div className="skeleton-line skeleton-line-full" />
                    <div className="skeleton-line skeleton-line-full" />
                </div>

                <div className="skeleton-avatar-sm skeleton-image rounded" />
        </div>
    );
}