import React from "react";
import { IconArrowNarrowLeft as BackwardIcon } from "@tabler/icons";
import { Link } from "react-router-dom";

import { Root, TitleContainer, Title } from "./styles";

function BreadCrumb({ path }) {
  return (
    <ol className="breadcrumb" aria-label="breadcrumbs">
      {path?.map((item, index) => {
        if (item.href)
          return (
            <li key={index} className="breadcrumb-item">
              <a href={item.href}>{item.label}</a>
            </li>
          );

        return <li key={index} className="breadcrumb-item active">{item.label}</li>;
      })}
    </ol>
  );
}

export default function BackwardAndTitle({
  loading,
  title,
  breadcrumb,
  className,
  backHref,
}) {
  if (loading)
    return (
      <Root className={className}>
        <div
          className="skeleton-image"
          style={{ width: "40px", height: "40px" }}
        />

        <TitleContainer>
          <div className="skeleton-heading" style={{ width: "200px" }} />
          <div className="skeleton-line" />
        </TitleContainer>
      </Root>
    );

  return (
    <Root className={className}>
      {backHref && (
        <Link
          className="btn btn-white btn-icon"
          aria-label="Button"
          to={backHref}
        >
          <BackwardIcon />
        </Link>
      )}

      <TitleContainer>
        <Title>{title}</Title>

        {breadcrumb && <BreadCrumb path={breadcrumb} />}
      </TitleContainer>
    </Root>
  );
}
