import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTransactionById } from "../../services/api";

export default function useLoadData() {
  const routerParams = useParams();
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        const { data: _transaction } = await getTransactionById({
          transaction_id: routerParams.id,
        });

        const API_RESPONSE = {
          transaction_id: _transaction.id,
          date: _transaction.date_created,
          customer: {
            name: _transaction.user.public_name,
            phone: _transaction.user.mobile,
            document: _transaction.user.document,
            email: _transaction.user.email,

            address: {
              zip_code: _transaction.user.zipcode,
              country: _transaction.user.country,
              state: _transaction.user.state,
              city: _transaction.user.city,
              district: _transaction.user.neighborhood,
              address: _transaction.user.street,
              number: _transaction.user.street_number,
              complement: _transaction.user.complementary,
            },
          },
          items: ["Wine Red", "Wine Gallo", "Wine Merlot"],
          payment: {
            id: _transaction.authorization_code,
            amount: _transaction.amount,
            installments: _transaction.installments,
            payment_method: _transaction.payment_method,
            card: {
              issuer: _transaction.card_info.card_brand,
              last_numbers: _transaction.card_info.last_digits,
              card_holder: "Adalberto Marques",
            },
            coupon: _transaction.coupon,
            type: "sale",
            status: _transaction.status,

            discount_rates: {
              financial_fees: _transaction.cost,
              kderno: "15",
            },
          },
        };

        setTransactionData(API_RESPONSE);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Falha ao buscar transações");
      }
    }

    init();
  }, []);

  return {
    transactionData,
    loading,
  };
}
