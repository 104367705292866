import React, { useState, useEffect } from 'react';

import Table from '../../components/Table';
import TableLoading from '../../components/Table/loading';
import Row from './TransactionsTableRow';

const columns = [
    { field: "status", headerName: "Status" },
    { field: "id", headerName: "ID" },
    { field: "date", headerName: "Data" },
    { field: "payment_method", headerName: "Forma Pgto" },
    { field: "amount", headerName: "Valor" }
];

export default function TransactionTable({ loading, transactionData }) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if(loading || !transactionData)
            return;

        const updatedRows = [];

        for (const [index, values] of transactionData.transactions.entries()) {
            updatedRows.push(
                <Row key={index} {...values} />
            );
        }

        setRows(updatedRows);
    }, [loading, transactionData]);

    if(loading)
        return (
            <TableLoading />
        );

    return (
        <Table columns={columns} rows={rows} />
    );
}