import React from "react";

import { Card, ListContainer } from "./styles";

export default function InfoCard({ resp }) {
  return (
    <Card resp={resp}>
      <div className="card-body">
        <div className="skeleton-line" style={{ maxWidth: "120px" }}></div>

        <ListContainer>
          <li>
            <div className="row align-items-center">
              <div className="col-4">
                <div className="skeleton-line"></div>
              </div>
              <div className="col-2 ms-auto text-end">
                <div className="skeleton-line"></div>
              </div>
            </div>
          </li>
          <li>
            <div className="row align-items-center">
              <div className="col-4">
                <div className="skeleton-line"></div>
              </div>
              <div className="col-2 ms-auto text-end">
                <div className="skeleton-line"></div>
              </div>
            </div>
          </li>
          <li>
            <div className="row align-items-center">
              <div className="col-4">
                <div className="skeleton-line"></div>
              </div>
              <div className="col-2 ms-auto text-end">
                <div className="skeleton-line"></div>
              </div>
            </div>
          </li>
        </ListContainer>
      </div>
    </Card>
  );
}
