import styled, { css } from "styled-components";

const VariantStyle = css`
  ${({ variant }) =>
    variant === 1
      ? css`
          .value {
            font-weight: bold;
          }
        `
      : variant === 2
      ? css`
          .key {
            font-size: 24px;
            font-weight: bold;
          }
        `
      : variant === 3
      ? css`
          .value {
            font-size: 20px;
            font-weight: bold;
          }
        `
      : variant === 4
      ? css`
          .key {
            font-weight: bold;
            border-bottom: 4px solid currentColor;
          }
          .value {
            font-size: 20px;
            font-weight: bold;
          }
        `
      : variant === 5
      ? css`
          .key {
            font-weight: bold;
          }
          .value {
            font-size: 15px;
          }
        `
      : null}
`;

export const Root = styled.div`
  position: relative;
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};
  flex-direction: column;
  padding: 8px;
  min-width: 150px;
  width: fit-content;

  & :not(:first-child) {
    margin-top: 4px;
  }

  ${VariantStyle}

  ${({ bTop }) =>
    bTop &&
    css`
      & .b-top {
        top: 0px;
        height: 4px;
        width: calc(100% - 16px);
        display: block;
      }
    `}

    ${({ bLeft }) =>
    bLeft &&
    css`
      & .b-left {
        left: 0px;
        height: calc(100% - 16px);
        width: 4px;
        display: block;
      }
    `}

    ${({ bBottom }) =>
    bBottom &&
    css`
      & .b-bottom {
        bottom: 0px;
        height: 4px;
        width: calc(100% - 16px);
        display: block;
      }
    `}

    ${({ bRight }) =>
    bRight &&
    css`
      & .b-right {
        right: 0px;
        height: calc(100% - 16px);
        width: 4px;
        display: block;
      }
    `}
`;

export const Border = styled.span`
  position: absolute;
  background: currentColor;
  border-radius: 4px;
  display: none;
`;

export const Key = styled.label`
  display: flex;
  align-items: center;
  word-break: break-all;

  & * {
    margin-left: 8px;
  }
`;

export const Value = styled.label`
  display: flex;
  align-items: center;
  word-break: break-all;

  & *:not(:first-child) {
    margin-left: 4px;
  }
`;

export const Percent = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
  ${({ goUp }) =>
    goUp
      ? css`
          color: green;
        `
      : css`
          color: red;
        `}
`;
