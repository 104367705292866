import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import InfoCard from "../../../../components/InfoCard";
import Skeleton from "./skeleton";

import { formatTotals, formatToPercentage } from "../../utils";
import { getBoleto } from "services/api";

const DEFAULT_DATA = {
  generated: 0,
  paid: 0,
  conversion_percetage: 0,
};

function BoletoCard() {
  const block = useSelector((state) => state.general.block);
  const days = useSelector((state) => state.general.days);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(DEFAULT_DATA);

  useEffect(() => {
    async function fetchCardData() {
      setLoading(true);

      try {
        const boletosData = await getBoleto(days, block.id);
        if (!boletosData) {
          throw new Error("empty data");
        }
        setData(boletosData);
        setLoading(false);
      } catch (error) {
        setData(DEFAULT_DATA);
        return setLoading(false);
      }
    }

    return fetchCardData();
  }, [block, days]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <InfoCard
      title="Boletos"
      data={[
        { label: "Gerados", value: formatTotals(data.generated) },
        { label: "Pagos", value: formatTotals(data.paid) },
        { label: "% de conversão", value: formatToPercentage(data.conversion_percetage) },
      ]}
    />
  );
}

export default BoletoCard;
