import React from "react";

import Layout from "components/Layout";

import BackwardAndTitle from "components/BackwardAndTitle";

import Details from "./Details";
import TransactionsTable from "./TransactionsTable";

import useLoadData from "./useLoadData";

import { Container } from "./styles";

export const PAYMENT_METHOD = {
  credit_card: "Cartão de crédito",
  boleto: "Boleto",
};

export default function CustomerDetails() {
  const { loading, customerData } = useLoadData();

  return (
    <Layout>
      <Container>
        <BackwardAndTitle
          loading={loading}
          title={'Cliente'}
          breadcrumb={[
            {
              href: "/",
              label: "Visão Geral",
            },
            {
              href: "/customers",
              label: "Clientes",
            },
            {
              label: `#${customerData?.id} - ${customerData?.name}`,
            },
          ]}
          backHref="/customers"
        />
        <Details loading={loading} customerData={customerData} />
        <TransactionsTable loading={loading} customerData={customerData} />
      </Container>
    </Layout>
  );
}
