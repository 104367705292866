import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Row } from "../../styles";
import { formatValue } from "../../utils";

import DonutChart from "./chart";
import Skeleton from "./skeleton";

import { getWithdraws } from "services/api";

const DEFAULT_DATA = {
  available_for_withdraw: 0,
  net_value: 0,
  transfers: 0,
  chartData: {
    labels: ["Você", "Kderno", "Operações Financeiras"],
    values: [0, 0, 0],
  },
};

function InfoCard() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(DEFAULT_DATA);

  const days = useSelector((state) => state.general.days);
  const block = useSelector((state) => state.general.block);

  useEffect(() => {
    async function getCardData() {
      setLoading(true);

      try {
        const resp = await getWithdraws(days, block.id);
        if (!resp) {
          throw new Error("empty data");
        }

        const chartData = {
          labels: ["Você", "Kderno", "Operações Financeiras"],
          values: [resp?.raising.block_owner, resp?.raising.kderno_value, resp?.raising.finantial_operations],
        };
        resp.chartData = chartData;
        setData(resp);
        setLoading(false);
      } catch (error) {
        setData(DEFAULT_DATA);
        setLoading(false);
      }
    }

    return getCardData();
  }, [days, block]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", minWidth: "300px" }}>
      <div className="card" style={{ overflow: "visible" }}>
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="subheader">Disponível para Saque (D+30)</div>
          </div>
          <div className="d-flex align-items-baseline">
            <div className="h1 mb-0 me-2">{formatValue(data?.available_for_withdraw)}</div>
          </div>
        </div>
        <div className="card-body">
          <Row>
            <div className="col-md-6" style={{ flex: 1 }}>
              <div>
                <div className="d-flex align-items-center">
                  <div className="subheader">Valor Líquido</div>
                </div>
                <div className="d-flex align-items-baseline">
                  <h4>{formatValue(data?.net_value)}</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{ flex: 1 }}>
              <div>
                <div className="d-flex align-items-center">
                  <div className="subheader">Repasses</div>
                </div>
                <div className="d-flex align-items-baseline">
                  <h4>{formatValue(data?.transfers)}</h4>
                </div>
              </div>
            </div>
          </Row>
        </div>
        <DonutChart data={data?.chartData} />
      </div>
    </div>
  );
}

export default InfoCard;
