import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Table = styled.table`
  margin-bottom: 0;
`;

export const TableContainer = styled.div`
  overflow: auto;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;
