import React from "react";

export default function NameAndEmail({ name, email }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ fontSize: 15 }}>{name}</div>
      <div style={{ fontSize: 13 }}>{email}</div>
    </div>
  );
}
