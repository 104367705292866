import styled from "styled-components";

export const DonutValues = styled.div`
  padding: 0px 24px 0px 16px;
  position: absolute;
  width: calc(100% - 120px);
  left: 120px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #232e3c;
  font-size: 12px;
  line-height: 16px;

  & .donut-label-container {
    display: flex;
    line-height: 24px;
    justify-content: space-between;
  }

  & .donut-value {
    font-weight: bold;
  }

  & label {
    display: flex;
    align-items: center;
  }

  & .label-color {
    min-width: 16px;
    min-height: 8px;
    margin-right: 8px;
  }
`;
