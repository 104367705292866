import React from "react";

function TableLoading() {
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            <th>
              <div style={{ width: "100px" }} className="skeleton-line"></div>
            </th>
            <th>
              <div style={{ width: "130px" }} className="skeleton-line"></div>
            </th>
            <th>
              <div style={{ width: "150px" }} className="skeleton-line"></div>
            </th>
            <th>
              <div style={{ width: "120px" }} className="skeleton-line"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <div
                    style={{ width: "100px" }}
                    className="skeleton-line"
                  ></div>
                </td>
                <td>
                  <div
                    style={{ width: "130px" }}
                    className="skeleton-line"
                  ></div>
                </td>
                <td>
                  <div
                    style={{ width: "120px" }}
                    className="skeleton-line"
                  ></div>
                </td>
                <td>
                  <div
                    style={{ width: "110px" }}
                    className="skeleton-line"
                  ></div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableLoading;
