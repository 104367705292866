const INITIAL_STATE = {
  days: 7,
  block: "",
  userBlocks: [],
  daysOptions: [7, 15, 30, 60, 90],
};

function generalReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case "@general/SET_DAYS":
      const { days } = payload;
      state = {
        ...state,
        days,
      };
      break;

    case "@general/SET_BLOCK":
      const { block } = payload;
      state = {
        ...state,
        block,
      };
      break;

    case "@general/SET_USER_BLOCKS":
      const { blocks } = payload;
      state = {
        ...state,
        userBlocks: [...blocks],
      };
      break;

    default:
      return state;
  }

  return state;
}

export default generalReducer;
