export default function formatPaymetMethod(method) {
  switch (method) {
    case "credit_card":
      return "Cartão de Crédito";
    case "waiting_payment":
      return "Aguardando Pagamento";
    case "boleto":
      return "Boleto";
    default:
      return "";
  }
}
