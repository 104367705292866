import { combineReducers } from "redux";

import generalReducer from "./general/reducer";
import userReducer from "./user/reducer";

const rootReducer = combineReducers({
  general: generalReducer,
  user: userReducer,
});

export default rootReducer;
