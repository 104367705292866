import React, { useState, useEffect } from "react";

import BackwardAndTitle from "../../components/BackwardAndTitle";

export default function PageTitle({ className, loading, transactionData }) {
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (loading || !transactionData) return;

    setTitle("Assinatura #" + transactionData.id);
  }, [loading, transactionData]);

  return (
    <BackwardAndTitle
      className={className}
      loading={loading}
      title={title}
      breadcrumb={[
        {
          href: "/",
          label: "Visão Geral",
        },
        {
          href: "/subscriptions",
          label: "Assinaturas",
        },
        { label: title },
      ]}
      backHref="/subscriptions"
    />
  );
}
