import React from "react";
import Chart from "react-apexcharts";

import { Card, Graph, ListContainer } from "./styles";

export default function GraphCard({ resp, data }) {
  const localData = {
    amount: 68230,
    range: ["", "", "", "", "", "", "", "", "", "", ""],
    test: [70, 15, 80, 23, 49, 60, 70, 91, 70, 15, 80],
  };

  const chartData = {
    options: {
      colors: ["#dfe2e7"],
      tooltip: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "top",
      },
      chart: {
        toolbar: {
          show: false,
        },
        stroke: {
          curve: "smooth",
        },
        id: "Skel",
        type: "area",
        tooltip: {
          enabled: false,
          enabledOnSeries: false,
        },
      },
      xaxis: {
        categories: [...localData.range],
        show: false,
        labels: { show: false },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        categories: [...localData.range],
        show: false,
        labels: { show: false },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        padding: { left: 0, right: 0 },
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "smooth",

        width: 2,
      },
    },
    series: [
      {
        name: "Tested",
        type: "area",
        data: [...localData.test],
      },
    ],
  };

  return (
    <Card resp={resp}>
      <div className="card-body">
        {!resp && <div className="skeleton-line" style={{ maxWidth: "140px" }}></div>}

        <div className="skeleton-heading" style={{ maxWidth: "100px" }}></div>
        <div className="skeleton-line" style={{ maxWidth: "140px" }}></div>
      </div>

      <Graph>
        <Chart options={chartData.options} series={chartData.series} type={"area"} width={"100%"} height={120} />
      </Graph>

      <ListContainer skeleton>
        <li>
          <div className="row align-items-center">
            <div className="col-4">
              <div className="skeleton-line"></div>
            </div>
            <div className="col-2 ms-auto text-end">
              <div className="skeleton-line"></div>
            </div>
          </div>
        </li>
        <li>
          <div className="row align-items-center">
            <div className="col-4">
              <div className="skeleton-line"></div>
            </div>
            <div className="col-2 ms-auto text-end">
              <div className="skeleton-line"></div>
            </div>
          </div>
        </li>
        <li>
          <div className="row align-items-center">
            <div className="col-4">
              <div className="skeleton-line"></div>
            </div>
            <div className="col-2 ms-auto text-end">
              <div className="skeleton-line"></div>
            </div>
          </div>
        </li>
      </ListContainer>
    </Card>
  );
}
