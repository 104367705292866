import React from "react";

export default function CustomDate({ date }) {
  const _date = new Date(date);

  if (!date) {
    return <div>------</div>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        {_date.getDate()}/{_date.getMonth() + 1}/{_date.getFullYear()}
      </div>
      <div>
        às {_date.getHours()}:{_date.getMinutes()}
      </div>
    </div>
  );
}
