import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

import { IconUser } from "@tabler/icons";

import ControlledDropdown, {
  ControlledDropdownItem,
} from "./ControlledDropdown";

export default function UserMenuItem() {
  const location = useLocation();
  const [open, setOpen] = useState({
    dropdown: false,
    leads: false,
    feedbacks: false,
  });

  useEffect(() => {
    const { pathname } = location;

    const leadsOpen = pathname === "/leads";
    const feedbacksOpen = pathname === "/feedbacks";
    const customersOpen = pathname === "/customers";
    const dropdownOpen = leadsOpen || feedbacksOpen || customersOpen;

    setOpen({
      dropdown: dropdownOpen,
      leads: leadsOpen,
      feedbacks: feedbacksOpen,
      customers: customersOpen,
    });
  }, [location]);

  return (
    <li
      className={classnames("nav-item dropdown", {
        ["dropup"]: open.dropdown,
      })}
    >
      <a
        className="nav-link dropdown-toggle"
        onClick={() =>
          setOpen((open) => ({ ...open, dropdown: !open.dropdown }))
        }
        href="/navbar-base"
        data-bs-toggle="dropdown"
        role="button"
        aria-expanded={open.dropdown}
      >
        <span className="nav-link-icon d-md-none d-lg-inline-block">
          <IconUser />
        </span>

        <span className="nav-link-title"> Usuários </span>
      </a>

      <ControlledDropdown open={open.dropdown}>
        <div className="dropdown-menu-columns">
          <div className="dropdown-menu-column">
            <ControlledDropdownItem active={open.customers} to="/customers">
              Clientes
            </ControlledDropdownItem>

            <ControlledDropdownItem active={open.leads} to="/leads">
              Leads
            </ControlledDropdownItem>

            <ControlledDropdownItem active={open.feedbacks} to="/feedbacks">
              Feedbacks
            </ControlledDropdownItem>
          </div>
        </div>
      </ControlledDropdown>
    </li>
  );
}
