import React from "react";
import Cards from "react-credit-cards";

import BlockKeyValue from "../../components/BlockKeyValue";
import DiscountRates from "./DiscountRates";

import {
  TransactionContainer,
  AmountDetailsContainer,
  AmountCard,
} from "./styles";

const PaymentStatus = {
  paid: '<span className="badge bg-green">Pago</span>',
  pending: '<span className="badge bg-yellow">Pentente</span>',
  failure: '<span className="badge bg-red">Falha</span>',
  waiting_payment: '<span className="badge bg-red">Aguardando pagamento</span>',
  refund: '<span className="badge bg-red">Estornado</span>',
};

export default function AmountDetails({ loading, transactionData }) {
  return (
    <TransactionContainer className="card">
      <div className="card-body">
        <div>
          <AmountDetailsContainer>
            <BlockKeyValue
              loading={loading}
              label="ID Transação"
              value={"#" + transactionData?.transaction_id}
              variant={1}
            />

            <AmountCard
              loading={loading}
              label={
                "R$ " +
                String(transactionData?.payment?.amount).replace(".", ",") +
                PaymentStatus[transactionData?.payment?.status]
              }
              value={
                "Parcelas <strong>" +
                transactionData?.payment?.installments +
                "x</strong>"
              }
              variant={2}
            />
          </AmountDetailsContainer>
          <DiscountRates loading={loading} transactionData={transactionData} />
        </div>
        {!loading && transactionData?.payment?.card && (
          <Cards
            issuer={transactionData?.payment?.card?.issuer}
            name={transactionData?.payment?.card?.card_holder}
            number={
              "**** **** **** " + transactionData?.payment?.card?.last_numbers
            }
            preview
            cvc=""
            expiry=""
          />
        )}
      </div>
    </TransactionContainer>
  );
}
