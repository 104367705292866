import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Layout from "components/Layout";

import CustomTable from "../../components/Table";
import LoadingTable from "../../components/Table/loading";
import NameAndEmail from "../../components/Table/components/NameAndEmail";
import CustomDate from "../../components/Table/components/CustomDate";
import DropdownButton from "../../components/DropdownButton";
import BackwardAndTitle from "../../components/BackwardAndTitle";
import ReactPaginate from "react-paginate";

import { getSubscriptions } from "../../services/api";

import formatAmount from "utils/formatAmount";
import formatPaymentMethod from "utils/formatPaymentMethod";

const Status = (color) => {
  return <span className="badge badge-pill bg-blue"></span>;
};

function Row({ data }) {
  const history = useHistory();

  const actions = [
    {
      label: "Ver detalhes",
      path: `/subscriptions/${data.id}`,
      action: () => {
        return history.push(`/subscriptions/${data.id}`);
      },
    },
  ];

  return (
    <tr>
      <td>
        <Status />
      </td>
      <td>
        <span>#{data.external_id}</span>
      </td>
      <td>
        <NameAndEmail name={data.user.public_name} email={data.user.email} />
      </td>
      <td>
        <CustomDate date={data.current_period_start} />
      </td>
      <td>
        <CustomDate date={data.current_period_end} />
      </td>
      <td>
        <span>{formatAmount(data.base_amount)}</span>
      </td>
      <td>{/* <span>R${data.amount}</span> */}</td>
      <td>
        <span>{formatPaymentMethod(data.payment_method)}</span>
      </td>
      <td>
        <span>{data.cupon_code ? data.cupon_code : ""}</span>
      </td>
      <td>
        <DropdownButton childs={actions} />
      </td>
    </tr>
  );
}

const columns = [
  { field: "status", headerName: "" },
  { field: "id", headerName: "ID" },
  { field: "name", headerName: "Nome" },
  { field: "start_at", headerName: "Data início" },
  { field: "start_at", headerName: "Data fim" },
  { field: "value", headerName: "Valor" },
  { field: "recurrency", headerName: "Recorrência" },
  { field: "payment_method", headerName: "Forma de pgto." },
  { field: "cupon_code", headerName: "Cupom" },
  { field: "actions", headerName: "" },
];

function Subscriptions() {
  const profile = useSelector((state) => state.user.profile.data);
  const block = useSelector((state) => state.general.block);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        const { data: subscriptions } = await getSubscriptions({
          page: currentPage,
          block_id: block?.id,
        });

        const _rows = [];

        for (const [index, value] of subscriptions.results.entries()) {
          _rows.push(<Row data={value} key={index} />);
        }

        setRows(_rows);
        setPageCount(Math.ceil(subscriptions.count / 10));
        setLoading(false);
      } catch (error) {
        console.error("Falha ao buscar clientes", error);
        setPageCount(1);
        setLoading(false);
      }
    }

    return init();
  }, [profile, block, currentPage]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "2rem",
          width: "100%",
        }}
      >
        <BackwardAndTitle
          loading={loading}
          title="Assinaturas"
          breadcrumb={[
            {
              href: "/",
              label: "Visão Geral",
            },
            {
              label: "Assinaturas",
            },
          ]}
        />
        <div className="card">
          {loading ? (
            <LoadingTable />
          ) : (
            <CustomTable columns={columns} rows={rows} />
          )}
          <div className="card-footer flex-end">
            <ReactPaginate
              previousLabel="Anterior"
              nextLabel="Próxima"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-content-end"
              activeClassName="active"
              pageClassName="page-item"
              nextClassName="page-link"
              pageLinkClassName="page-link"
              previousClassName="page-link"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Subscriptions;
