import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

const days = 120;

export async function getAllBlocks() {
  try {
    const { data } = await api.get("kderno/subscription-block/");
    return data;
  } catch (error) {
    return [];
  }
}

export function getProfile() {
  return new Promise((resolve, reject) => {
    api
      .get("/perfil/")
      .then((res) => resolve(res.data))
      .catch(reject);
  });
}

export function getCustomers({ page = 1, block_id = null }) {
  let url = `/dashboard/customers/?days=${days}&page=${page}`;

  if (block_id) {
    url = url.concat(`&block_id=${block_id}`);
  }

  return api.get(url);
}

export function getCustomerById({ user_id }) {
  return api.get(`/dashboard/customers/${user_id}/`);
}

export function getSubscriptions({ page = 1, block_id = null }) {
  let url = `/dashboard/subscriptions/?days=${days}&page=${page}`;

  if (block_id) {
    url = url.concat(`&block_id=${block_id}`);
  }

  return api.get(url);
}

export function getSubscriptionsById({ subscription_id }) {
  return api.get(`/dashboard/subscriptions/${subscription_id}/`);
}

export function getTransactions({
  block_id = null,
  user_id = null,
  subscription_id = null,
  page = 1,
  days = 7,
}) {
  let url = `/dashboard/transactions/?days=${days}&page=${page}`;

  if (block_id) {
    url = `/dashboard/transactions/?days=${days}&block_id=${block_id}&page=${page}`;
  }

  if (subscription_id) {
    url = `/dashboard/transactions/?days=${days}&subscription=${subscription_id}`;
  }

  if (user_id) {
    url = `/dashboard/transactions/?days=${days}&user=${user_id}&page=${page}`;
  }

  return api.get(url);
}

export function getTransactionById({ transaction_id }) {
  return api.get(`/dashboard/transactions/${transaction_id}/`);
}

export function getLeads({ page = 1, days = 7 }) {
  let url = `/dashboard/dashboard/leads/?page=${page}&days=${days}`;

  return api.get(url);
}

export function getFeedbacks({ page = 1, days = 7 }) {
  let url = `/dashboard/dashboard/feedbacks/?page=${page}&days=${days}`;

  return api.get(url);
}

export async function getBoleto(days = 7, block_id = "") {
  try {
    let url = `/dashboard/general-boleto-box/?days=${days}`;

    if (block_id) {
      url = url.concat(`&block_id=${block_id}`);
    }

    const { data } = await api.get(url);

    if (!Object.keys(data).length) {
      return null;
    }
    return data;
  } catch (err) {
    return null;
  }
}

export async function getPerformance(days = 7, block_id = "") {
  try {
    let url = `/dashboard/general-performance-box/?days=${days}`;

    if (block_id) {
      url = url.concat(`&block_id=${block_id}`);
    }

    const { data } = await api.get(url);

    if (!Object.keys(data).length) {
      return null;
    }
    return data;
  } catch (err) {
    return null;
  }
}

export async function getVisits(days = 7, block_id = "") {
  try {
    let url = `/dashboard/general-visit-box/?days=${days}`;

    if (block_id) {
      url = url.concat(`&block_id=${block_id}`);
    }

    const { data } = await api.get(url);

    if (!Object.keys(data).length) {
      return null;
    }
    return data;
  } catch (err) {
    return null;
  }
}

export async function getIncomes(days = 7, block_id = "") {
  try {
    let url = `/dashboard/general-income-box/?days=${days}`;

    if (block_id) {
      url = url.concat(`&block_id=${block_id}`);
    }

    const { data } = await api.get(url);

    if (!Object.keys(data).length) {
      return null;
    }
    return data;
  } catch (err) {
    return null;
  }
}

export async function getWithdraws(days = 7, block_id = "") {
  try {
    let url = `/dashboard/general-withdraw-box/?days=${days}`;

    if (block_id) {
      url = url.concat(`&block_id=${block_id}`);
    }

    const { data } = await api.get(url);

    if (!Object.keys(data).length) {
      return null;
    }
    return data;
  } catch (err) {
    return null;
  }
}

export async function getSales(days = 7, block_id = "") {
  try {
    let url = `/dashboard/general-sales-box/?days=${days}`;

    if (block_id) {
      url = url.concat(`&block_id=${block_id}`);
    }

    const { data } = await api.get(url);

    if (!Object.keys(data).length) {
      return null;
    }
    return data;
  } catch (err) {
    return null;
  }
}

export async function getPaymentSubscriptions(days = 7, block_id = "") {
  try {
    let url = `/dashboard/general-subscription-box/?days=${days}`;

    if (block_id) {
      url = url.concat(`&block_id=${block_id}`);
    }

    const { data } = await api.get(url);

    if (!Object.keys(data).length) {
      return null;
    }
    return data;
  } catch (err) {
    return null;
  }
}

export async function getDonations(days = 7, block_id = "") {
  try {
    let url = `/dashboard/general-donations-box/?days=${days}`;

    if (block_id) {
      url = url.concat(`&block_id=${block_id}`);
    }

    const { data } = await api.get(url);

    if (!Object.keys(data).length) {
      return null;
    }
    return data;
  } catch (err) {
    return null;
  }
}

export default api;
