import React, { useState, useEffect } from "react";

import BlockKeyValue from "../../components/BlockKeyValue";
import pad from "../../utils/pad";

import { PAYMENT_METHOD } from "./index";
import { SubscriptionDetailsContainer } from "./styles";

export default function MoreDetails({ loading, transactionData }) {
  const [updatedDate, setUpdatedDate] = useState("");

  useEffect(() => {
    if (loading || !transactionData) return;

    const updatedAt = new Date(transactionData.updatedAt);

    setUpdatedDate(
      `${pad(updatedAt.getDate())}/${pad(updatedAt.getMonth() + 1)}/${String(updatedAt.getFullYear()).substring(2, 4)} ${pad(
        updatedAt.getHours()
      )}/${pad(updatedAt.getMinutes())}h`
    );
  }, [loading, transactionData]);

  return (
    <SubscriptionDetailsContainer>
      <section className="card">
        <div className="card-header">
          {loading ? <div className="skeleton-line" style={{ width: "200px" }}></div> : <strong>Detalhes da assinatura</strong>}
        </div>
        <div className="card-body">
          <BlockKeyValue inline loading={loading} label="Última atualização" value={updatedDate} variant={5} />

          <BlockKeyValue inline loading={loading} label="Plano" value={transactionData?.subscription?.plan} variant={5} />
        </div>
      </section>

      <section className="card">
        <div className="card-header">
          {loading ? <div className="skeleton-line" style={{ width: "200px" }}></div> : <strong>Informações de cobrança</strong>}
        </div>
        <div className="card-body">
          <div>
            <BlockKeyValue inline loading={loading} label="Nome" value={transactionData?.subscription?.name} variant={5} />

            <BlockKeyValue inline loading={loading} label="Cobranças" value={transactionData?.subscription?.charges} variant={5} />
          </div>
          <div>
            <BlockKeyValue inline loading={loading} label="Email" value={transactionData?.subscription?.email} variant={5} />

            <BlockKeyValue
              inline
              loading={loading}
              label="Formas de pagamento"
              value={transactionData?.subscription?.payment_methods.map((payment_method) => PAYMENT_METHOD[payment_method]).join("<br />")}
              variant={5}
            />
          </div>
        </div>
      </section>

      {loading ? (
        <div className="skeleton-image" style={{ width: "176px", height: "40px" }}></div>
      ) : (
        <a href={transactionData?.subscription?.pagarme_url} className="btn btn-white" target="_blank" rel="noreferrer">
          Gerenciar assinatura
        </a>
      )}
    </SubscriptionDetailsContainer>
  );
}
