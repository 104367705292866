import styled from "styled-components";

export const MainCard = styled.div`
  width: 100%;
  min-height: 420px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    margin-left: ${(props) => props.gap && props.gap};
    margin-right: ${(props) => props.gap && props.gap};
    margin-top: ${(props) => props.margin && props.margin};
    margin-bottom: ${(props) => props.margin && props.margin};
  }
`;

export const TabbedContainer = styled.div.attrs({ className: "card" })`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin-left: ${(props) => props.gap && props.gap};
    margin-right: ${(props) => props.gap && props.gap};
    margin-top: ${(props) => props.margin && props.margin};
    margin-bottom: ${(props) => props.margin && props.margin};
  }
`;

export const Tabs = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #ddd;
`;

export const Tab = styled.div`
  cursor: pointer;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #656d77;
  font-weight: bold;
  border-bottom: ${(props) => (props.active ? "2px solid #206BC4" : "2px solid transparent")};
  transition: border-bottom ease 0.5s;
  padding: 0px 20px;
`;
