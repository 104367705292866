import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  .card-body {
    width: 70%;
  }

  @media screen and (max-width: 991px) {
    padding: 8px;
  }
`;

export const SubscriptionDetailsContainer = styled.div`
  margin: 2rem 0 1rem 0;

  & > :not(:first-child) {
    margin-top: 16px;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    width: 70%;

    & > div {
      display: flex;

      & > div {
        flex: 1;
      }
    }
  }
`;
