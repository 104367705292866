import styled from 'styled-components';

export const Card = styled.div.attrs((props) => ({
    className: "card",
}))`
    min-width: 300px;
    flex: 1;
    height: auto;
    max-height: 300px;
  
    & .card-body {
      /* padding: 1rem 1rem 0rem 1rem; */
    }
  
    & .signal-icon {
      width: 16px;
      height: 16px;
    }
  
    & .text-gray {
      color: #999;
    }
  
    & .percentage {
      font-size: 12px;
    }
`;