import React from "react";

import { Container, LeftSection, RightSection } from "./styles";
import UserMenu from "../UserMenu";
import FilterButtons from "../FilterButtons";

function Header() {
  return (
    <Container>
      <LeftSection>
        <FilterButtons />
      </LeftSection>
      <RightSection>
        <UserMenu />
      </RightSection>
    </Container>
  );
}

export default Header;
