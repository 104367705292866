import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCustomerById, getTransactions } from "../../services/api";

export default function useLoadData() {
  const routerParams = useParams();
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        const { data: _customer } = await getCustomerById({
          user_id: routerParams.id,
        });

        // Transações do Cliente
        const { data: _transactions } = await getTransactions({
          user_id: _customer.user.id,
        });

        let transactions = [];

        if (_transactions.results.length) {
          for (const transaction of _transactions.results) {
            transactions.push({
              status: transaction.status,
              id: transaction.id,
              date: transaction.created_at,
              payment_method: transaction.payment_method,
              type: "-",
              amount: transaction.amount,
            });
          }
        }

        setTimeout(() => {
          const API_RESPONSE = {
            id: _customer.user.id,
            createdAt: _customer.customer_since,

            name: _customer.user.name,
            phone: _customer.user.mobile,
            document: _customer.user.document,
            email: _customer.user.email,
            total_transacted: _customer.total_paid,

            address: {
              zip_code: _customer.user.zipcode,
              country: _customer.user.country,
              state: _customer.user.state,
              city: _customer.user.city,
              district: _customer.user.neighborhood,
              address: _customer.user.street,
              number: _customer.user.street_number,
              complement: _customer.user.complementary,
            },
            transactions,
          };

          setCustomerData(API_RESPONSE);
          setLoading(false);
        }, 2000);
      } catch (error) {
        console.error("Falha ao buscar dados do cliente");
      }
    }

    init();
  }, []);

  return {
    customerData,
    loading,
  };
}
